import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import parse from "html-react-parser";

import { ROUTE_OTHER, ROUTE_OTHER_CONTACT, routes } from "../../routes";

import {
  SOCIAL_LINK_FACEBOOK,
  SOCIAL_LINK_INSTAGRAM,
  SOCIAL_LINK_LINKEDIN,
  SOCIAL_LINK_TWITTER,
  SOCIAL_LINK_YOUTUBE
} from "../../config/social";

import logoWithoutName from "../../assets/images/logoWithoutName.png";

import PrimaryButton from "../Buttons/PrimaryButton";
import SuccessButton from "../Buttons/SuccessButton";

import OpenTicket from "../OpenTicket";

const StyledFooterWrapper = styled.div`
  background-color: ${props => props.theme.blue2};
`;
const StyledFooter = styled.div`
  padding-top: ${props => (props.isMobile ? "50px" : "100px")};
  margin-bottom: ${props => (props.isMobile ? "50px" : "80px")};
`;

const StyledContact = styled.div`
  padding: ${props => (props.isMobile ? "50px 15px" : "100px")};
  background-color: ${props => props.theme.neutralBase};
  color: ${props => props.theme.white};

  & .btn {
    margin-top: 20px;
  }
`;

const StyledContactTitle = styled.h2`
  font-size: 29px;
  font-weight: 600;
  line-height: 1.41;
  margin-bottom: 10px;

  @media (max-width: 320px) {
    font-size: 24px;
  }

  & a {
    color: ${props => props.theme.white};
  }
`;

const StyledContactSubtitle = styled.p`
  font-size: 16px;
  line-height: 1.63;
`;

const StyledFooterLogo = styled.div`
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 30px;
`;
const StyledLinksContainer = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 170px;
  margin-right: ${props => (props.isMobile ? "0" : "30px")};
  margin-bottom: ${props => props.isMobile && "30px"};
  font-size: 14px;
  color: ${props => props.theme.neutral6};
  &:last-child {
    margin-right: 0;
  }

  & strong {
    display: block;
    margin-bottom: 10px;
  }

  & strong:not(:first-child) {
    margin-top: 20px;
  }

  & a {
    color: ${props => props.theme.neutral6};
    display: block;
    margin-bottom: 10px;
  }
  & a:hover {
    color: ${props => props.theme.neutral5};
    text-decoration: none;
  }
`;

const StyledPaymentGateways = styled.ul`
  display: ${props => props.isMobile && "flex"};
  float: ${props => (props.isMobile ? "none" : "left")};
  justify-content: ${props => props.isMobile && "space-between"};
  & li {
    float: left;
    height: 50px;
    margin-right: ${props => (props.isMobile ? "0px" : "25px")};
    width: ${props => props.isMobile && "50px"};
  }
  & li:nth-child(6) {
    margin-right: ${props => (props.isMobile ? "0px" : "10px")};
  }

  & li img {
    display: block;
  }

  & li img.halkbank {
    margin-top: 10px;
    width: ${props => (props.isMobile ? "33px" : "153px")};
  }

  & li img.mastercard {
    width: ${props => (props.isMobile ? "33px" : "63px")};
  }

  & li img.verified-visa {
    width: ${props => (props.isMobile ? "33px" : "53px")};
    margin-top: ${props => (props.isMobile ? "6px" : "10px")};
  }

  & li img.visa {
    width: ${props => (props.isMobile ? "33px" : "92px")};
    margin-top: 10px;
  }

  & li img.maestro {
    width: ${props => (props.isMobile ? "33px" : "63px")};
  }

  & li img.mastercard-secure {
    width: ${props => (props.isMobile ? "33px" : "84px")};
    margin-top: 10px;
  }

  & li img.checkout {
    margin-top: ${props => (props.isMobile ? "4px" : "-14px")};
    width: 155px;
  }

  & li img.marnet {
    margin-top: 10px;
    width:150px;
    margin-left:-28px;
    filter: grayscale(100%);
  }
`;

const StyleCopyright = styled.div`
  border-top: 1px solid ${props => props.theme.neutral3};
  padding-top: 20px;
  padding-bottom: 20px;
  color: ${props => props.theme.neutral6};
`;

const StyledSocial = styled.div`
  float: ${props => !props.isMobile && "right"};
  text-align: ${props => props.isMobile && "center"};
  & li {
    margin-top: 4px;
    margin-right: 10px;
    display: inline-block;
    height: 42px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const StyledPaymentSocialWrapper = styled.div`
  border-top: 1px solid ${props => props.theme.neutral3};
  padding-top: 30px;
  padding-bottom: 20px;
`;

const StyledButtonLink = styled(Link)`
  margin-right: 15px;
`;

const Footer = () => (
  <Translate>
    {({ translate, activeLanguage }) => (
      <footer>
        <StyledFooterWrapper className="row">
          <StyledContact
            className="col-12 col-sm-12 col-md-12"
            isMobile={isMobileOnly}
          >
            <div className="row">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-7">
                    <StyledContactTitle>
                      {parse(translate("footer.contact-title"))}
                    </StyledContactTitle>
                    <StyledContactSubtitle>
                      {parse(translate("footer.contact-info"))}
                    </StyledContactSubtitle>
                    {/* <StyledContactSubtitle>
                      {parse(translate("footer.contact-description"))}
                    </StyledContactSubtitle> */}
                  </div>
                  <div className="col-12 col-sm-12 col-md-5 text-sm-right">
                    <StyledButtonLink to={ROUTE_OTHER_CONTACT}>
                      <PrimaryButton size="l">
                        {translate("footer.contact-view-details-button")}
                      </PrimaryButton>
                    </StyledButtonLink>

                    {/* <OpenTicket>
                      {onShow => (
                        <SuccessButton size="l" onClick={() => onShow()}>
                          {translate("footer.contact-open-ticket-button")}
                        </SuccessButton>
                      )}
                    </OpenTicket> */}
                  </div>
                </div>
              </div>
            </div>
          </StyledContact>
          <StyledFooter
            className="col-12 col-sm-12 col-md-12"
            isMobile={isMobileOnly}
          >
            <div className="row">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12">
                    {!isMobileOnly && (
                      <StyledFooterLogo>
                        <img src={logoWithoutName} height="40px" alt="Mkhost" />
                      </StyledFooterLogo>
                    )}
                    {routes.map(
                      (parentRoute, index) =>
                        parentRoute.groups && parentRoute.groups.length > 0 ? (
                          <StyledLinksContainer
                            isMobile={isMobileOnly}
                            className=""
                            key={`footer-title-${index}`}
                          >
                            {parentRoute.title !== "Other" && (
                              <strong>{translate(parentRoute.title)}</strong>
                            )}
                            {parentRoute.groups.map((group, index) => {
                              const renderGroupTitle =
                                parentRoute.path === ROUTE_OTHER;

                              return (
                                <React.Fragment key={`footer-wrap-${index}`}>
                                  {renderGroupTitle &&
                                    group.title &&
                                    group.title.length > 0 && (
                                      <strong>{translate(group.title)}</strong>
                                    )}
                                  {group.routes.map(
                                    (route, index) => {
                                      if (activeLanguage && route.hideOn !== activeLanguage.code) {
                                        return (route.path ? (
                                          <Link
                                            to={
                                              route.path.indexOf(":") > -1
                                                ? route.path.split(":")[0]
                                                : route.path
                                            }
                                            key={`footer-item-${index}`}
                                          >
                                            {translate(route.title)}
                                          </Link>
                                        ) : (
                                          <a
                                            key={`footer-item-${index}`}
                                            href={route.link}
                                            target="_blank"
                                            rel="nofollow noopenner"
                                          >
                                            {translate(route.title)}
                                          </a>
                                        )
                                        )
                                      }
                                    }
                                  )}
                                </React.Fragment>
                              );
                            })}
                          </StyledLinksContainer>
                        ) : (
                          <Link
                            key={`nav-item-${index}`}
                            to={parentRoute.path}
                            id={`menu-${index}`}
                            className={`nav-link ${parentRoute.path === this.props.location.path
                              ? "active"
                              : ""
                              }`}
                          >
                            {translate(parentRoute.title)}
                          </Link>
                        )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </StyledFooter>
          <div className="col-12 col-sm-12 col-md-12">
            <div className="row">
              <div className="container">
                <div className="row">
                  <StyledPaymentSocialWrapper className="col-12 col-sm-12 col-md-12">
                    <StyledPaymentGateways
                      className="list-unstyled"
                      isMobile={isMobileOnly}
                    >
                      <li>
                        <img
                          className="img-fluid halkbank"
                          src="/assets/icons/payment/logo-halkbank.svg"
                          alt=""
                        />
                      </li>
                      <li>
                        <img
                          className="img-fluid mastercard"
                          src="/assets/icons/payment/logo-mastercard.svg"
                          alt=""
                        />
                      </li>
                      <li>
                        <img
                          className="img-fluid verified-visa"
                          src="/assets/icons/payment/logo-verified-by-visa.svg"
                          alt=""
                        />
                      </li>
                      <li>
                        <img
                          className="img-fluid visa"
                          src="/assets/icons/payment/logo-visa.svg"
                          alt=""
                        />
                      </li>
                      <li>
                        <img
                          className="img-fluid maestro"
                          src="/assets/icons/payment/logo-maestro.svg"
                          alt=""
                        />
                      </li>
                      <li>
                        <img
                          className="img-fluid mastercard-secure"
                          src="/assets/icons/payment/logo-mastercard-securecode.svg"
                          alt=""
                        />
                      </li>
                      <li>
                        <img
                          className="img-fluid checkout"
                          src="/assets/icons/payment/logo-checkout.png"
                          alt=""
                        />
                      </li>
                      <li>
                        <a href="https://marnet.mk" target="_blank">
                          <img
                            className="img-fluid marnet"
                            src="/assets/images/marnet_logo.png"
                            alt=""
                          />
                        </a>

                      </li>
                    </StyledPaymentGateways>
                    <StyledSocial
                      className="list-unstyled"
                      isMobile={isMobileOnly}
                    >
                      <li>
                        <a
                          href={SOCIAL_LINK_FACEBOOK}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src="/assets/icons/social/icon-social-facebook.svg"
                            alt="MKhost Facebook"
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href={SOCIAL_LINK_LINKEDIN}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src="/assets/icons/social/icon-social-linkedin.svg"
                            alt="MK LinkedIn"
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href={SOCIAL_LINK_TWITTER}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src="/assets/icons/social/icon-social-twitter.svg"
                            alt="MK Twitter"
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href={SOCIAL_LINK_INSTAGRAM}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src="/assets/icons/social/icon-social-instagram.svg"
                            alt="MK Instagram"
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href={SOCIAL_LINK_YOUTUBE}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src="/assets/icons/social/icon-social-youtube.svg"
                            alt="MK Youtube"
                          />
                        </a>
                      </li>
                    </StyledSocial>
                  </StyledPaymentSocialWrapper>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12">
            <div className="row">
              <div className="container">
                <div className="row">
                  <StyleCopyright className="col-12 col-sm-12 col-md-12">
                    © {new Date().getFullYear()} {translate("footer.mkhost")}.{" "}
                    {translate("footer.all-rights-reserved")}
                  </StyleCopyright>
                </div>
              </div>
            </div>
          </div>
        </StyledFooterWrapper>
      </footer>
    )}
  </Translate>
);

export default Footer;

import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";

import Hero from "../../components/Hero";

import ContainerModal from "../../components/Containers/ContainerModal";
import BackgroundSpacer from "../../components/BackgroundSpacer";
import SectionTitle from "../../components/SectionTitle";
import SectionDescription from "../../components/SectionDescription";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import BulletPoint from "../../components/BulletPoint";

import {ROUTE_404, ROUTE_WEBSITE_DOMAIN} from "../../routes";
import {Helmet} from "react-helmet";
import seoData from "../../seo/seoData";

const StyledJobPostWrapper = styled.div`
  margin-top: -98px;
  margin-bottom: 98px;
`;

const StyledContent = styled.div`
  padding-top: 100px;
  padding-bottom: 150px;
`;

const StyledTitle = styled.h2`
  margin: 0;
  margin-bottom: 18px;
  font-size: 28px;
  line-height: 1.33;
  font-weight: 600;
  color: ${props => props.theme.neutralBase};
`;
const StyledDescription = styled(SectionDescription)`
  margin-bottom: 50px;
`;

const StyledBackgroundSpacer = styled(BackgroundSpacer)`
  position: absolute;
  display: block;
  z-index: -1;
  top: -33px;
`;

const StyledContainer = styled(ContainerModal)`
  padding: 40px 30px;
  margin-bottom: 20px;
  & .title {
    display: block;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.56;
    color: ${props => props.theme.neutralBase};
    margin-bottom: 20px;
  }

  & .content {
    display: block;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 1.63;
    color: ${props => props.theme.neutral6};
  }
`;

class OtherJobPostPage extends React.Component {
  componentDidMount() {
    if 
    (
      this.props.match.params.post &&
      (
        // this.props.match.params.post !== "praksa-vo-mkhost-system-administrator" &&
        this.props.match.params.post !== "rabota-vo-mkhost-system-administrator" &&
     this.props.match.params.post !== "rabota-vo-mkhost-web-developer" &&
        this.props.match.params.post !== "rabota-vo-mkhost-agent-po-prodazba" &&
        this.props.match.params.post !== "rabota-vo-mkhost-specijalist-tehnicka-poddrska"
      )
    ) 
    {
      return this.props.history.push(ROUTE_404);
    }
  }

  renderSystemAdminJob(activeLanguage, translate) {
    return (
      <React.Fragment>
        {this.renderSocialMediaMeta(activeLanguage, 'system_admin')}
        <Hero
          title="Систем администратор"
          backgroundImage="/assets/backgrounds/bg-3-1.svg"
          backgroundPosition="top"
          backgroundPositionY="110px"
          backgroundSize={isMobileOnly ? "cover" : "contain"}
        >
          <StyledBackgroundSpacer height="125px" />
        </Hero>

        <StyledJobPostWrapper className="row">
          <div className="col-12 col-sm-12 col-md-12">
            <div className="row">
              <div className="container">
                {" "}
                <a href="mailto:jobs@mkhost.com.mk?subject=Систем администратор">
                  <PrimaryButton size="l">
                    {translate("apply-now")}
                  </PrimaryButton>
                </a>
              </div>
            </div>
          </div>
        </StyledJobPostWrapper>

        <StyledContent className="row">
          <div className="col-12 col-sm-12 col-md-12">
            <div className="row">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-7">
                    <SectionTitle>
                      Оглас за работа во МКхост (Систем администратор)
                    </SectionTitle>
                    <StyledDescription>
                      Најголемиот македонски хостинг провајдер и регистрар на домени МКхост, објавува отворен конкурс за вработување, за позицијата систем администратор.
                    </StyledDescription>

                    <StyledTitle>
                      Кои се главните задачи за оваа работна позиција?
                    </StyledTitle>

                    <SectionDescription>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Управување, мониторинг, upgrade и контрола врз безбедноста на Linux системите
                      </BulletPoint>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Изведување на редовни операции поврзани со Backup решенија и спроведување на соодветни процеси за заштита на податоците
                      </BulletPoint>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Надминување на разни предизвици поврзани со инфраструктурата и услугите на корисниците на МКхост
                      </BulletPoint>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Обезбедување на професионална техничка поддршка за корисниците на МКхост (преку телефон, емаил и ticketing system)
                      </BulletPoint>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Автоматизација на процеси на инфраструктурно ниво
                      </BulletPoint>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Менаџирање и справување со серверски инциденти
                      </BulletPoint>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Брза и ефикасна тимска/корисничка комуникација
                      </BulletPoint>
                    </SectionDescription>

                    <StyledTitle>Потребни квалификации и вештини</StyledTitle>
                    <SectionDescription>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Работно искуство со серверска инфраструктура базирана на Linux/Windows оперативни системи
                      </BulletPoint>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Солидно познавање на работа во терминал
                      </BulletPoint>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Солидно познавање на функционирањето на сервисите HTTP, DNS, MySQL, SSH, IMAP итн.
                      </BulletPoint>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Работа со Domain Name System-и (DNS)
                      </BulletPoint>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Искуство со автоматизација на процеси
                      </BulletPoint>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Познавање или работно искуство со AWS cloud сервиси (EC/Load Balancing/Elastic IP/Snapshot)
                      </BulletPoint>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Инсталација и конфигурација на CMS системи (WordPress, Joomla, Drupal)
                      </BulletPoint>
                    </SectionDescription>

                    <StyledTitle>Што би те издвоило?</StyledTitle>
                    <SectionDescription>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Одредено искуство во веб хостинг индустријата (познавање и работа во најкористените светски платформи како cPanel/Plesk)
                      </BulletPoint>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Способност за создавање на скрипти (Bash/Python/Ruby)
                      </BulletPoint>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Искуство со автоматизација на серверска инфраструктура (Ansible/Puppet)
                      </BulletPoint>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Познавање на CI/CD pipeline интеграции
                      </BulletPoint>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Искуство со алатки за log management и инфраструктурно мониторирање
                      </BulletPoint>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Работно искуство и познавање на Cloud сервиси (AWS)
                      </BulletPoint>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Самоиницијативност, желба за учење и проактивност во работата
                      </BulletPoint>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Снаодливост и способност за надминување на инфраструктурни проблеми
                      </BulletPoint>
                    </SectionDescription>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 offset-md-1">
                    <StyledContainer>
                      <span className="title">
                        Што значи да се биде дел од тимот на МКхост?
                      </span>
                      <p className="content">
                        <span>
                          Работата во МКхост е исполнета преку пријателска, динамична и тимски организирана средина, со можности за персонално и професионално унапредување. Погледни како изгледа еден работен ден во МКхост{" "}
                          <a
                            href="https://blog.mkhost.com.mk/kako-izgleda-eden-raboten-den-vo-timot-na-mkhost/"
                            target="_blank"
                            rel="rel noopener nofollow"
                          >
                            тука
                          </a>
                          !
                        </span>
                      </p>
                    </StyledContainer>

                    <StyledContainer>
                      <span className="title">Дополнителни информации</span>
                      <p className="content">
                        Работното време во МКхост е од понеделник до петок, 08:00-16:00 и од 14:00-22:00
                      </p>
                      <p className="content">
                        <b>Во моментов поради Корона криза работата се одвива од дома.</b>
                      </p>

                    </StyledContainer>

                    <StyledContainer>
                      <span className="title">Како да аплицирам?</span>
                      <p className="content">
                        <span>
                          Испрати го твоето CV со назнака во наслов „Систем администратор“ на{" "}
                          <a href="mailto:jobs@mkhost.com.mk?subject=Апликација за работна позиција систем администратор">
                            jobs@mkhost.com.mk
                          </a>{" "}најдоцна до 01.04.2022. Доколку имаш дополнителни прашања обрати ни се на{" "}
                          <a href="https://facebook.com/mkhost">Facebook</a> или пиши ни.
                        </span>
                      </p>

                      <a href="mailto:jobs@mkhost.com.mk?subject=Апликација за работна позиција систем администратор">
                        <PrimaryButton size="l">
                          {translate("apply-now")}
                        </PrimaryButton>
                      </a>
                    </StyledContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </StyledContent>
      </React.Fragment>
    );
  }

  renderWebDeveloperJob(activeLanguage, translate) {
    return (
      <React.Fragment>
        {this.renderSocialMediaMeta(activeLanguage, 'web_developer')}
        <Hero
          title={"FrontEnd Developer"}
          backgroundImage="/assets/backgrounds/bg-3-1.svg"
          backgroundPosition="bottom"
          backgroundPositionY={isMobileOnly ? "18px" : "120px"}
          backgroundSize={isMobileOnly ? "cover" : "auto"}
        >
          <StyledBackgroundSpacer height="125px" />
        </Hero>

        <StyledJobPostWrapper className="row">
          <div className="col-12 col-sm-12 col-md-12">
            <div className="row">
              <div className="container">
                {" "}
                <a href="mailto:jobs@mkhost.com.mk?subject=FrontEnd Developer">
                  <PrimaryButton size="l">Аплицирај веднаш</PrimaryButton>
                </a>
              </div>
            </div>
          </div>
        </StyledJobPostWrapper>

        <StyledContent className="row">
          <div className="col-12 col-sm-12 col-md-12">
            <div className="row">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-7">
                    <SectionTitle>
                      Оглас за работа во МКхост (FrontEnd Developer)
                    </SectionTitle>
                    <StyledDescription>
                      МКхост објавува отворен конкурс за вработување, за позицијата FrontEnd девелопер.
                      Kандидатите за отворената позициja ќе работат на веќе постоечки проекти,
                      како и во развојот на нови проекти за домашниот и меѓународниот пазар.
                    </StyledDescription>

                    <StyledTitle>
                      Кои се главните задачи за оваа работна позиција?
                    </StyledTitle>

                    <SectionDescription>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Дизајн и развој на динамички веб апликации со React.js
                      </BulletPoint>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Разрешување на проблеми-багови, како и проблеми со компатибилност

                      </BulletPoint>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Контрибуција во сите фази од development lifecycle
                      </BulletPoint>
                      <BulletPoint
                          type="arrow"
                          icon="arrow_right"
                          color="greenBase"
                      >
                        Мониторирање и подобрување на front-end performance
                      </BulletPoint>
                      <BulletPoint
                          type="arrow"
                          icon="arrow_right"
                          color="greenBase"
                      >
                        Документирање на промени на апликација

                      </BulletPoint>

                    </SectionDescription>

                    <StyledTitle>

                    </StyledTitle>
                    <SectionDescription>

                      <StyledTitle>
                        Кои моментални познавања би те издвоиле?
                      </StyledTitle>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Одлично познавање и минимум 2 години искуство со React.js, JavaScript, CSS, HTML

                      </BulletPoint>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Одлично познавање и искуство со веб сервиси

                      </BulletPoint>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Искуство со Redux, RESTful APIs, version control (GIT), и JIRA.

                      </BulletPoint>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Искуство со User interface дизајн

                      </BulletPoint>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Претходно искуство со изработка на custom Wordpress сајтови

                      </BulletPoint>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Високо познавање и работа со OOP и SOLID principles


                      </BulletPoint>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Високо познавање и разбирање на различни софтверски архитектури

                      </BulletPoint>

                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Претходно искуство со работење во агилна девелопмент средина

                      </BulletPoint>


                    <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                    >
                      Одлични способности за комуникација и документирање
                    </BulletPoint>
                      <BulletPoint
                          type="arrow"
                          icon="arrow_right"
                          color="greenBase"
                      >
                        Способност за себе-менаџирање и почитување на рокови
                      </BulletPoint>


                    </SectionDescription>


                    <StyledTitle>
                      Следниве познавања ќе се сметаат за дополнителна предност:
                    </StyledTitle>
                    <SectionDescription>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Искуство UI/UX

                      </BulletPoint>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Искуство со различни видови на data storages (Redis, Neo4j, и сл.)

                      </BulletPoint>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Знаење и/или искуство со различни системи за автентикација и авторизација (oAuth2, JWT, и сл.)

                      </BulletPoint>

                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Искуство со CI/CD, DevOps и Cloud (AWS / GCP / DigitalOcean)


                      </BulletPoint>

                    </SectionDescription>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 offset-md-1">
                    <StyledContainer>
                      <span className="title">
                        Што значи да се биде дел од тимот на МКхост?
                      </span>
                      <p className="content">
                        <span>
                          Работата во МКхост е исполнета преку пријателска, динамична и тимски организирана средина, со можности за персонално и професионално унапредување.


                        </span>
                      </p>
                    </StyledContainer>

                    <StyledContainer>
                      <span className="title">Дополнителни информации</span>
                      <p className="content">
                        Работното време во МКхост е од понеделник до петок,
                        08:00-16:00, работата се одвива од дома.
                      </p>

                    </StyledContainer>

                    <StyledContainer>
                      <span className="title">Како да аплицирам?</span>
                      <p className="content">
                        <span>
                          Испрати го твоето CV со назнака во наслов „ Front Web Developer“ на{" "}
                          <a href="mailto:jobs@mkhost.com.mk?subject=Апликација за работна позиција FrontEnd Developer">
                            jobs@mkhost.com.mk
                          </a>{" "}
                          најдоцна до 15.12.2022. Доколку имаш дополнителни
                          прашања обрати ни се на{" "}
                          <a href="https://facebook.com/mkhost">Facebook</a> или
                          пиши ни.
                        </span>
                      </p>

                      <a href="mailto:jobs@mkhost.com.mk?subject=Апликација за работна позиција FrontEnd Developer">
                        <PrimaryButton size="l">
                          {translate("apply-now")}
                        </PrimaryButton>
                      </a>
                    </StyledContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </StyledContent>
      </React.Fragment>
    );
  }
  renderSalesAgentJob(activeLanguage, translate) {
    return (
      <React.Fragment>
        {this.renderSocialMediaMeta(activeLanguage, 'sales_agent')}
        <Hero
          title={"Агент за продажба"}
          backgroundImage="/assets/backgrounds/bg-3-1.svg"
          backgroundPosition="bottom"
          backgroundPositionY={isMobileOnly ? "18px" : "120px"}
          backgroundSize={isMobileOnly ? "cover" : "auto"}
        >
          <StyledBackgroundSpacer height="125px" />
        </Hero>

        <StyledJobPostWrapper className="row">
          <div className="col-12 col-sm-12 col-md-12">
            <div className="row">
              <div className="container">
                {" "}
                <a href="mailto:jobs@mkhost.com.mk?subject=Агент за продажба">
                  <PrimaryButton size="l">Аплицирај веднаш</PrimaryButton>
                </a>
              </div>
            </div>
          </div>
        </StyledJobPostWrapper>

        <StyledContent className="row">
          <div className="col-12 col-sm-12 col-md-12">
            <div className="row">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-7">
                    <SectionTitle>
                      Оглас за работа во МКхост (Агент за продажба и поддршка)
                    </SectionTitle>
                    <StyledDescription>
                      Најголемиот македонски хостинг провајдер и регистрар на домени МКхост,
                      објавува отворен конкурс за вработување, за позицијата агент за поддршка и продажба.
                    </StyledDescription>

                    <StyledTitle>
                      Кои се главните задачи за оваа работна позиција?
                    </StyledTitle>

                    <SectionDescription>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Закажување состаноци и преговарање со активни и потенцијални клиенти околу закуп на услуги на
                        МКхост преку директен состанок (виртуелно или физички), телефон, емаил, тикети и chat комуникација.
                        Водење на целиот процес од почеток до крај.



                      </BulletPoint>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Активно истражување и зголемување на своето знаење околу IT индустријата
                        (хостинг - основен, дедицирани и cloud сервери, веб решенија, cloud архитектури и имплементации, консултации и целосни решенија)

                      </BulletPoint>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Градење на силни, долгорочни врски со клиентите преку разбирање на нивните потреби,
                        добра комуникација, како и составување на соодветни понуди за нив.

                      </BulletPoint>
                      <BulletPoint
                          type="arrow"
                          icon="arrow_right"
                          color="greenBase"
                      >
                        Дефинирање на цени преку мониторирање на трошоци, побарувачка и заинтересираност на клиентите.

                      </BulletPoint>
                      <BulletPoint
                          type="arrow"
                          icon="arrow_right"
                          color="greenBase"
                      >
                        Соработка со маркетинг тимот за креирање на месечни, квартални и годишни планови за продажба.

                      </BulletPoint>
                      <BulletPoint
                          type="arrow"
                          icon="arrow_right"
                          color="greenBase"
                      >
                        Дизајнирање и имплементација на стратегиски бизнис планови кои би довеле до зголемување на бројот на клиенти и продажбата.


                      </BulletPoint>
                      <BulletPoint
                          type="arrow"
                          icon="arrow_right"
                          color="greenBase"
                      >
                        Организирање на follow-up состаноци со активни клиенти



                      </BulletPoint>
                      <BulletPoint
                          type="arrow"
                          icon="arrow_right"
                          color="greenBase"
                      >
                        Подобрување на корисничкото искуство на клиентите на МКхост, со нудење на дополнителни вредности за нив.



                      </BulletPoint>
                    </SectionDescription>
                    <StyledTitle>
                      Потребни квалификации и вештини

                    </StyledTitle>
                    <SectionDescription>
                      <BulletPoint
                          type="arrow"
                          icon="arrow_right"
                          color="greenBase"
                      >
                        Претходно успешно искуство како sales representative (2+ години)

                      </BulletPoint>
                      <BulletPoint
                          type="arrow"
                          icon="arrow_right"
                          color="greenBase"
                      >
                        Добри способности за говорна комуникација


                      </BulletPoint>
                       <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                    >
                      Добри способности за пишувана комуникација


                    </BulletPoint>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                    >
                      Претходно искуство со водење на sales процес - од план до завршување на истиот.

                    </BulletPoint>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                    >
                      Тимска работа, самоиницијативност и превземање на одговорност.


                    </BulletPoint>
                      <BulletPoint
                          type="arrow"
                          icon="arrow_right"
                          color="greenBase"
                      >
                        Познавање на компјутерски вештини.


                      </BulletPoint>
                      <BulletPoint
                          type="arrow"
                          icon="arrow_right"
                          color="greenBase"
                      >
                        Познавање на англиски јазик.



                      </BulletPoint>




                    </SectionDescription>

                    <StyledTitle>
                      Кои моментални познавања би те издвоиле?
                    </StyledTitle>
                    <SectionDescription>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Одредено познавање на индустријата за домени, веб хостинг, продажба на софтвер

                      </BulletPoint>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Претходно искуство со спремање на тендерска документација и аплицирање на тендерски постапки

                      </BulletPoint>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Претходно исксуство со директни B2B состаноци со големи корпорации и клиенти

                      </BulletPoint>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Претходно искуство како team lead

                      </BulletPoint>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Аналитичност во работата

                      </BulletPoint>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Проактивност и самоиницијативност

                      </BulletPoint>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Тимска работа

                      </BulletPoint>



                    </SectionDescription>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 offset-md-1">
                    <StyledContainer>
                      <span className="title">
                        Што значи да се биде дел од тимот на МКхост?
                      </span>
                      <p className="content">
                        <span>
                          Работата во МКхост е исполнета преку пријателска,
                          динамична и тимски организирана средина, со можности
                          за персонално и професионално унапредување. Погледни
                          како изгледа еден работен ден во МКхост{" "}
                          <a
                            href="https://blog.mkhost.com.mk/kako-izgleda-eden-raboten-den-vo-timot-na-mkhost/"
                            target="_blank"
                            rel="rel noopener nofollow"
                          >
                            тука
                          </a>
                          !
                        </span>
                      </p>
                    </StyledContainer>

                    <StyledContainer>
                      <span className="title">Дополнителни информации</span>
                      <p className="content">
                        Работното време во МКхост е од понеделник до петок,
                        08:00-16:00 и од 14:00-22:00.
                      </p>
                      <p className="content">
                        <b>Во моментов поради Корона криза работата се одвива од дома.</b>
                      </p>
                    </StyledContainer>

                    <StyledContainer>
                      <span className="title">Како да аплицирам?</span>
                      <p className="content">
                        <span>
                          Испрати го твоето CV со назнака во наслов „Агент за продажба и поддршка“ на{" "}
                          <a href="mailto:jobs@mkhost.com.mk?subject=Апликација за работна позиција агент за продажба и поддршка">
                            jobs@mkhost.com.mk
                          </a>{" "}
                          најдоцна до 01.04.2022. Доколку имаш дополнителни
                          прашања обрати ни се на{" "}
                          <a href="https://facebook.com/mkhost">Facebook</a> или
                          пиши ни.
                        </span>
                      </p>

                      <a href="mailto:jobs@mkhost.com.mk?subject=Апликација за работна позиција aгент за продажба и поддршка">
                        <PrimaryButton size="l">
                          {translate("apply-now")}
                        </PrimaryButton>
                      </a>
                    </StyledContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </StyledContent>
      </React.Fragment>
    );
  }

  renderSpecialistJob(activeLanguage, translate) {
    return (
      <React.Fragment>
        {this.renderSocialMediaMeta(activeLanguage, 'technical_support')}
        <Hero
          title={"Специјалист за техничка поддршка"}
          leftClassName="col-12 col-sm-12 col-md-6"
          rightClassName="col-12 col-sm-12 col-md-6"
          backgroundImage="/assets/backgrounds/bg-3-1.svg"
          backgroundPosition="bottom"
          backgroundPositionY={isMobileOnly ? "18px" : "120px"}
          backgroundSize={isMobileOnly ? "cover" : "auto"}
        >
          <StyledBackgroundSpacer height="125px" />
        </Hero>

        <StyledJobPostWrapper className="row">
          <div className="col-12 col-sm-12 col-md-12">
            <div className="row">
              <div className="container">
                {" "}
                <a href="mailto:jobs@mkhost.com.mk?subject=Специјалист за техничка поддршка">
                  <PrimaryButton size="l">Аплицирај веднаш</PrimaryButton>
                </a>
              </div>
            </div>
          </div>
        </StyledJobPostWrapper>

        <StyledContent className="row">
          <div className="col-12 col-sm-12 col-md-12">
            <div className="row">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-7">
                    <SectionTitle>
                      Оглас за работа во МКхост (Специјалист за техничка
                      поддршка)
                    </SectionTitle>
                    <StyledDescription>
                      Најголемиот македонски хостинг провајдер и регистрар на домени МКхост, објавува отворен конкурс за вработување, за позицијата Специјалист за техничка поддршка.
                    </StyledDescription>

                    <StyledTitle>
                      Кои се главните задачи за оваа работна позиција?
                    </StyledTitle>

                    <SectionDescription>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Обезбедување на професионална техничка поддршка за корисниците на МКхост (преку телефон, емаил, ticketing system и online chat).
                      </BulletPoint>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Решавање на различни предизвици поврзани со серверите и услугите на корисниците на МКхост.
                      </BulletPoint>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Менаџирање и справување со серверски инциденти и проблеми.
                      </BulletPoint>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Брза и ефикасна комуникација со корисниците и со колегите.
                      </BulletPoint>
                    </SectionDescription>

                    <StyledTitle>Потребни квалификации и вештини</StyledTitle>
                    <SectionDescription>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Искуство со работа на Linux оперативни ситеми и познавање на работа во терминал.
                      </BulletPoint>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Познавање на функционирањето на сервисите HTTP, DNS, MySQL, SSH, IMAP итн.
                      </BulletPoint>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Работа со Domain Name System-и (DNS).
                      </BulletPoint>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Инсталација и конфигурација на CMS системи (WordPress, Joomla, Drupal).
                      </BulletPoint>
                    </SectionDescription>

                    <StyledTitle>Што би те издвоило?</StyledTitle>
                    <SectionDescription>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Одредено искуство во веб хостинг и домени индустријата (познавање и работа во најкористените светски платформи cPanel, Plesk и работа со домени).
                      </BulletPoint>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Претходно искуство во работа со клиенти.
                      </BulletPoint>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Трпеливост и комуникативност.
                      </BulletPoint>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Самоиницијативност, желба за учење и проактивност во работата.
                      </BulletPoint>
                      <BulletPoint
                        type="arrow"
                        icon="arrow_right"
                        color="greenBase"
                      >
                        Снаодливост и способност за брзо надминување на кориснички проблеми.
                      </BulletPoint>
                    </SectionDescription>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 offset-md-1">
                    <StyledContainer>
                      <span className="title">
                        Што значи да се биде дел од тимот на МКхост?
                      </span>
                      <p className="content">
                        <span>
                          Работата во МКхост е исполнета преку пријателска, динамична и тимски организирана средина, со можности за персонално и професионално унапредување. Погледни како изгледа еден работен ден во МКхост{" "}
                          <a
                            href="https://blog.mkhost.com.mk/kako-izgleda-eden-raboten-den-vo-timot-na-mkhost/"
                            target="_blank"
                            rel="rel noopener nofollow"
                          >
                            тука
                          </a>
                          !
                        </span>
                      </p>
                    </StyledContainer>

                    <StyledContainer>
                      <span className="title">Дополнителни информации</span>
                      <p className="content">
                        Работното време во МКхост е од понеделник до петок, 08:00-16:00 и од 14:00-22:00.
                      </p>
                      <p className="content">
                        <b>Во моментов поради Корона криза работата се одвива од дома.</b>
                      </p>

                    </StyledContainer>

                    <StyledContainer>
                      <span className="title">Како да аплицирам?</span>
                      <p className="content">
                        <span>
                          Испрати го твоето CV со назнака во наслов „Специјалист за техничка поддршка“ на{" "}
                          <a href="mailto:jobs@mkhost.com.mk?subject=Апликација за работна позиција Специјалист за техничка поддршка">
                            jobs@mkhost.com.mk
                          </a>{" "}најдоцна до 01.03.2022. Доколку имаш дополнителни прашања обрати ни се на{" "}
                          <a href="https://facebook.com/mkhost">Facebook</a> или пиши ни.
                        </span>
                      </p>

                      <a href="mailto:jobs@mkhost.com.mk?subject=Апликација за работна позиција Специјалист за техничка поддршка">
                        <PrimaryButton size="l">
                          {translate("apply-now")}
                        </PrimaryButton>
                      </a>
                    </StyledContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </StyledContent>
      </React.Fragment>
    );
  }

  /**
   * reusable function for render social meta tags for specific jobs
   * @param activeLanguage
   * @param type ['web_developer, sales_agent, system_admin, technical_support,']
   * @returns {JSX.Element}
   */
  renderSocialMediaMeta(activeLanguage, type){
    if(activeLanguage && activeLanguage.code){
      return (
          <Helmet>
            <title>
              {seoData[activeLanguage.code].careers[type].title}
            </title>
            <meta
                name="keywords"
                content={
                  seoData[activeLanguage.code].careers[type].meta_keywords
                }
            />
            <meta
                name="description"
                content={
                  seoData[activeLanguage.code].careers[type].meta_description
                }
            />
            <meta
                property="og:title"
                content={seoData[activeLanguage.code].careers[type].title}
            />
            <meta
                property="og:description"
                content={
                  seoData[activeLanguage.code].careers[type].description
                }
            />
            <meta
                property="og:image"
                content={`${ROUTE_WEBSITE_DOMAIN}/assets/share/${seoData[activeLanguage.code].careers[type].socialImage}`}
            />
            <meta
                name="twitter:title"
                content={seoData[activeLanguage.code].careers[type].title}
            />
            <meta
                name="twitter:description"
                content={
                  seoData[activeLanguage.code].careers[type].meta_description
                }
            />
            <meta
                name="twitter:image"
                content={`${ROUTE_WEBSITE_DOMAIN}/assets/share/${seoData[activeLanguage.code].careers[type].socialImage}`}
            />
          </Helmet>)
    }

  }

  render() {
    return (
      <Translate>
        {({ translate, activeLanguage }) => {

          if(this.props.match.params.post){
            switch (this.props.match.params.post) {
              case "rabota-vo-mkhost-system-administrator" :  {
                return this.renderSystemAdminJob(activeLanguage, translate);
              }
              case "rabota-vo-mkhost-web-developer" :  {
                return this.renderWebDeveloperJob(activeLanguage, translate);
              }
              case "rabota-vo-mkhost-agent-po-prodazba" :  {
                return this.renderSalesAgentJob(activeLanguage, translate);
              }
              case "rabota-vo-mkhost-specijalist-tehnicka-poddrska" :  {
                return this.renderSpecialistJob(activeLanguage, translate);
              }
              default : {
                return null;
              }
            }
          }
        }}
      </Translate>
    );
  }
}
export default withRouter(OtherJobPostPage);

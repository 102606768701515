import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { Link, withRouter } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import { Helmet } from "react-helmet";

import * as hostingService from "../../services/hostingService";
import * as cartService from "../../services/cartService";

import DataContext from "../../DataContext";

import withLoading from "../../components/Loading/WithLoading";
import ContentLoader from "../../components/Loading/ContentLoader";

import displayToastMessageForResponse from "../../utils/displayToastMessageForResponse";

import {
    ROUTE_CLIENT_CART,
    ROUTE_HOSTING_BUSINESS_HOSTING, ROUTE_HOSTING_WINDOWS_HOSTING, ROUTE_HOSTING_WORDPRESS_HOSTING,
    ROUTE_OTHER_CONTACT,
    ROUTE_SERVERS_CLOUD,
    ROUTE_WEBSITE_DOMAIN
} from "../../routes";

import Hero from "../../components/Hero";
import BlogPostCard from "../../components/Cards/BlogPostCard";
import { AccordionItem } from "../../components/Accordion";
import ContentWidget from "../../components/ContentWidget";
import MostPopularArticlesSection from "../../components/Sections/MostPopularArticlesSection";
import TestimonialsSection from "../../components/Sections/TestimonialsSection";
import DomainsSearchSection from "../../components/Sections/DomainsSearchSection";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import BackgroundSpacer from "../../components/BackgroundSpacer";
import HostingPlan from "../../components/HostingPlan";
import Modal from "../../components/Modal";
import InputField from "../../components/InputField";
import Label from "../../components/Label";

import HostingPageHostingPlans from "../../components/Sections/HostingPageHostingPlans";
import PremiumPackageSection from "../../components/Sections/PremiumPackageSection";
import CustomControlPanelSection from "../../components/Sections/CustomControlPanelSection";
import OneClickAppInstallSection from "../../components/Sections/OneClickAppInstallSection";
import WhyHostWithMKhostSection from "../../components/Sections/WhyHostWithMKhostSection";
import SupportTypesSection from "../../components/Sections/SupportTypesSection";
import OneClickAppInstallBlocksSection from "../../components/Sections/OneClickAppInstallBlocksSection";

import seoData from "../../seo/seoData";
import { FAQ_CATEGORY_HOSTING } from "../../config/faq";
import * as ebookDisplayService from "../../services/ebookDisplayService";
import SubscribeFreeEbookModal from "../../components/SubscribeFreeEbookModal/SubscribeFreeEbookModal";
import Container from "../../components/Containers/Container";
import SmallDescription from "../../components/SmallDescription";
import SectionDescription from "../../components/SectionDescription";
import SectionTitle from "../../components/SectionTitle";
import Perk from "../../components/Perk";
import SupportPlan from "../../components/SupportPlan";
import FeaturesTable from "../../components/FeaturesTable";
import check_success from "../../assets/images/check_success.svg";
import parse from "html-react-parser";
import SmallTitle from "../../components/SmallTitle";
import CustomTooltip from "../../components/CustomTooltip";

const StyledHostingWebPage = styled.div`
  & .hosting-hosting-plans {
    margin-top: ${props => (props.isMobile ? "60px" : "30px")};
  }
`;

const StyledBackgroundSpacer = styled(BackgroundSpacer)`
  position: absolute;
  z-index: -1;
  top: ${props => (props.isMobile ? "0" : "-87px")};
  left: 0;
  width: 100%;
`;

const hostingPlansImages = {
    basic: "/assets/icons/icon_web1.png",
    regular: "/assets/icons/icon_web2.png",
    power: "/assets/icons/icon_web3.png"
};
const StyledTop = styled.div`
  margin-bottom: 40px;
`;
const StyledAvaiable = styled.img``;

const FeaturesTableSection = styled.div`
  margin-top: -159px;
  padding-bottom: 75px;
`;
const StypedTop = styled.div`
  margin-bottom: 50px;
  padding-left:20px;
`;

const StyledHeader = styled.h2`
  font-size: 36px;
  color: #263857;
  font-weight: 600;
  margin-bottom: 18px;
`;


const StyledContainer = styled(Container)`
  padding: 30px;
  margin-bottom: 30px;
`;

const StyledPrice = styled.span`
  display: inline-block;
  font-size: 26px;
  font-weight: 700;
  color: ${props => props.theme.blueBase};
`;

const StyledPeriod = styled.span`
  font-size: 16px;
  color: ${props => props.theme.blueBase};
  font-weight: 400;
`;

const StyledSmallDescription = styled(SmallDescription)`
  margin-bottom: 50px;
`;
const features = [

    {
        name: {
            en: "Number of websites",
            mk: "Број на сајтови"
        },
        "Basic": "2",
        "Regular": "5",
        "Power": "10"
    },
    {
        name: {
            en: "Disk space",
            mk: "Хостинг простор"
        },
        "Basic": "2GB",
        "Regular": "10GB",
        "Power": "30GB"
    },
    {
        name: {
            en: "Traffic bandwidth",
            mk: "Трансфер"
        },
        translate: true,
        Basic: { mk: "200 GB Трансфер", en: "200 GB" },
        Regular: { mk: "500 GB Трансфер", en: " 500 GB" },
        Power: { mk: "700 GB Трансфер", en: " 700 GB" },
    },
    {
        name: {
            en: "RAM memory",
            mk: "RAM меморија"
        },
        translate: true,
        Basic: { mk: "NVME SSD RAID ", en: "NVME SSD RAID " },
        Regular: { mk: "NVME SSD RAID ", en: " NVME SSD RAID " },
        Power: { mk: "NVME SSD RAID ", en: " NVME SSD RAID " },

    },
    {
        name: {
            en: "Parked domains",
            mk: "Parked домени"
        },
        "Basic": "1",
        "Regular": "5",
        "Power": "10"
    },
    {
        name: {
            en: "Addon domains",
            mk: "Addon домени"
        },
        "Basic": "1",
        "Regular": "5",
        "Power": "10"
    },
    {
        name: {
            en: "Number of emails",
            mk: "Број на емаил адреси"
        },
        translate: true,
        Basic: { mk: "неограничено", en: "unlimited" },
        Regular: { mk: "неограничено", en: " unlimited" },
        Power: { mk: "неограничено", en: " unlimited" },
    },
    {
        name: {
            en: "Number of databases",
            mk: "Број на бази"
        },
        translate: true,
        Basic: { mk: "неограничено", en: "unlimited" },
        Regular: { mk: "неограничено", en: " unlimited" },
        Power: { mk: "неограничено", en: " unlimited" },
    },
    {
        name: {
            en: "Server location",
            mk: "Серверска локација"
        },
        translate: true,
        Basic: { mk: "Европа и Америка", en: "Europe and America" },
        Regular: { mk: "Европа и Америка", en: " Europe and America" },
        Power: { mk: "Европа и Америка", en: " Europe and America" },

    },
    {
        name: {
            en: "Physical memory",
            mk: "Физичка меморија"
        },
        "Basic": "1024MB (1GB)",
        "Regular": "2048MB (2GB)",
        "Power": "3062MB (3GB)"
    },
    {
        name: {
            en: "Processor speed",
            mk: "Процесорска брзина"
        },
        translate: true,
        Basic: { mk: "100% (1 jадро)", en: "100% (1 core)" },
        Regular: { mk: "200% (2 jадро)", en: "200% (2 core)" },
        Power: { mk: "300% (3 jадро)", en: "300% (3 core)" },
    },
    {
        name: {
            en: "PHP version",
            mk: "Избор на PHP верзија"
        },
        "Basic": "5.6, 7.0, 7.1, 7.2, 7.3 , 7.4 и 8.0",
        "Regular": "5.6, 7.0, 7.1, 7.2, 7.3 и 7.4 и 8.0",
        "Power": "5.6, 7.0, 7.1, 7.2, 7.3 и 7.4 и 8.0"
    },
    {
        name: {
            en: "Files (inodes)",
            mk: "Files (inodes)"
        },
        "Basic": "300.000 - 350.000",
        "Regular": "350.000 - 400.000",
        "Power": "400.000 - 450.000"
    }
    , {
        name: {
            en: "Entry processes",
            mk: "Entry processes"
        },
        "Basic": "10-40",
        "Regular": "20-60",
        "Power": "40-100"
    },


    {
        name: {
            en: "Auto SSL  certificate",
            mk: "Auto SSL сертификат"
        },
        "Basic": true,
        "Regular": true,
        "Power": true
    },
    {
        name: {
            en: "Backup",
            mk: "Backup"
        },
        "Basic": true,
        "Regular": true,
        "Power": true
    },
    {
        name: {
            en: "Regular backup ",
            mk: "Редовен backup"
        },
        translate: true,
        Basic: { mk: "дневно, неделно и месечно", en: "daily, weekly and monthly" },
        Regular: { mk: "дневно, неделно и месечно", en: "daily, weekly and monthly" },
        Power: { mk: "дневно, неделно и месечно", en: "daily, weekly and monthly" },
    },
    {
        name: {
            en: "Offsite backup",
            mk: "Offsite backup"
        },
        translate: true,
        Basic: { mk: "3 пати годишно", en: "3 times per year" },
        Regular: { mk: "3 пати годишно", en: "3 times per year" },
        Power: { mk: "3 пати годишно", en: "3 times per year" },
    },
    {
        name: {
            en: "cPanel Control panel",
            mk: "cPanel Контролен панел"
        },
        "Basic": true,
        "Regular": true,
        "Power": true
    },

    {
        name: {
            en: "WordPress Installation",
            mk: "WordPress Инсталација"
        },
        "Basic": true,
        "Regular": true,
        "Power": true
    },
    {
        name: {
            en: "Softaculous",
            mk: "Softaculous"
        },
        "Basic": true,
        "Regular": true,
        "Power": true
    }


];
const HostingPlanWithLoading = withLoading(HostingPlan, ContentLoader);

const tutorialTextMk = "Брз и едноставен <a href='https://mkhost.com/mk/other/email/' target='_blank'>туторијал</a> за подесување на твојот маил.";
const tutorialTextEN = "Fast and easy <a href='https://mkhost.com/en/other/email/' target='_blank'>tutorial</a> with instructions how to set up your email on any device.";

class HostingWebPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showDomainModal: null,
            domain: "",
            hosting: "",
            submitting: false,
            showSubscribeFreeEbookModal: false,
            ebookSeen: ebookDisplayService.shouldEbookShow()
        };
    }

    onChangeDomain = e => {
        this.setState({ domain: e.target.value });
    };

    onShowModal = product => {
        this.setState({
            hosting: product,
            showDomainModal: true
        });
    };

    onCloseModal = () => {
        this.setState({
            showDomainModal: false
        });
    };

    onAddToCart = () => {
        const product = {
            ...this.state.hosting,
            domain: this.state.domain
        };
        cartService.add(product).then(result => {
            const { error } = result.data;
            const { messages, cart } = result.data.data;
            displayToastMessageForResponse(
                "Shop",
                { error, messages },
                `${ROUTE_CLIENT_CART}?cart=${cart.id}`,
                "View Cart"
            );
        });
    };

    onHideSubscribeFreeEbookModal = () => {
        this.setState({
            showSubscribeFreeEbookModal: false
        });
    };

    onScrollShowFreeEbookModal = e => {
        if (
            window.scrollY > 200 &&
            !this.state.showSubscribeFreeEbookModal &&
            !this.state.ebookSeen
        ) {
            this.setState(
                {
                    showSubscribeFreeEbookModal: true,
                    ebookSeen: true
                },
                () => {
                    ebookDisplayService.markEbookAsSeen();
                }
            );
        }
    };

    // componentDidMount() {
    //     window.addEventListener("scroll", this.onScrollShowFreeEbookModal);
    // }

    // componentWillUnmount() {
    //     window.removeEventListener("scroll", this.onScrollShowFreeEbookModal);
    // }

    render() {
        return (
            <Translate>
                {({ translate, activeLanguage }) => (
                    <StyledHostingWebPage className="row" isMobile={isMobileOnly}>
                        {activeLanguage && activeLanguage.code && (
                            <Helmet>
                                <title>
                                    {seoData[activeLanguage.code].hosting_web_hosting.title}
                                </title>
                                <meta
                                    name="keywords"
                                    content={
                                        seoData[activeLanguage.code].hosting_web_hosting
                                            .meta_keywords
                                    }
                                />
                                <meta
                                    name="description"
                                    content={
                                        seoData[activeLanguage.code].hosting_web_hosting
                                            .meta_description
                                    }
                                />
                                <meta
                                    property="og:title"
                                    content={
                                        seoData[activeLanguage.code].hosting_web_hosting.title
                                    }
                                />
                                <meta
                                    property="og:description"
                                    content={
                                        seoData[activeLanguage.code].hosting_web_hosting
                                            .meta_description
                                    }
                                />
                                <meta
                                    property="og:image"
                                    content={`${ROUTE_WEBSITE_DOMAIN}/assets/share/illustration_hosting.png`}
                                />

                                <meta
                                    name="twitter:title"
                                    content={
                                        seoData[activeLanguage.code].hosting_web_hosting.title
                                    }
                                />
                                <meta
                                    name="twitter:description"
                                    content={
                                        seoData[activeLanguage.code].hosting_web_hosting
                                            .meta_description
                                    }
                                />
                                <meta
                                    name="twitter:image"
                                    content={`${ROUTE_WEBSITE_DOMAIN}/assets/share/illustration_hosting.png`}
                                />
                            </Helmet>
                        )}
                        <div className="col-12 col-sm-12 col-md-12">
                            <Hero
                                title={() => (
                                    <span>
                                        {translate("web")} {translate("hosting")}
                                    </span>
                                )}
                                subtitle={translate(
                                    "fast-and-reliable-space-for-your-website.-choose-from-3-different-web-hosting-plans-and-get-started-on-your-website"
                                )}
                                image="/assets/illustrations/hosting/illustration_hosting.png"
                                backgroundImage="/assets/backgrounds/bg-1-1.svg"
                                paddingBottom={isMobileOnly ? "0" : "0"}
                                backgroundPosition={isMobileOnly && "top"}
                                backgroundPositionY={isMobileOnly ? "10px" : "115px"}
                                backgroundSize={isMobileOnly ? "cover" : "auto"}
                            >
                                <StyledBackgroundSpacer
                                    isMobile={isMobileOnly}
                                    height={isMobileOnly ? "187px" : "270px"}
                                />
                            </Hero>
                            <HostingPageHostingPlans>

                                <DataContext.Consumer>
                                    {({ hostings: { web }, onShowAssignDomainToHosting }) => {
                                        return (
                                            <div
                                                key={`hosting-plans-${Math.random()}`}
                                                className="row"
                                            >
                                                {!web && (
                                                    <React.Fragment>
                                                        <div className="col-12 col-sm-12 col-md-4">
                                                            <HostingPlanWithLoading isLoading={true} />
                                                        </div>
                                                        <div className="col-12 col-sm-12 col-md-4">
                                                            <HostingPlanWithLoading isLoading={true} />
                                                        </div>
                                                        <div className="col-12 col-sm-12 col-md-4">
                                                            <HostingPlanWithLoading isLoading={true} />
                                                        </div>
                                                    </React.Fragment>
                                                )}
                                                {web &&
                                                    web.map(hosting => {
                                                        if (
                                                            hosting.pricing.buy.billing_cycle.derivations
                                                                .length === 0
                                                        )
                                                            return;

                                                        let possiblePeriodNumericCodes = [0.1, 0.3, 0.5, 1, 2, 3];
                                                        let pricing = null;

                                                        // const pricing = hosting.pricing.buy.billing_cycle.derivations.find(
                                                        //   d => d.numeric_code === 1
                                                        // );

                                                        // Temporary solution, should be sync with api derivation numeric codes
                                                        possiblePeriodNumericCodes.some(numericCode => {
                                                            pricing = hosting.pricing.buy.billing_cycle.derivations.find(
                                                                d => d.numeric_code === numericCode
                                                            );

                                                            if (typeof (pricing) !== 'undefined') {
                                                                // console.log(numericCode);
                                                                return true;
                                                            }
                                                        });
                                                        // END

                                                        if (!pricing) {
                                                            return;
                                                        }

                                                        let detailsExtra = hosting.details.extra;

                                                        return (
                                                            <div
                                                                key={`hosting-plan-${hosting.id}`}
                                                                className="col-12 col-sm-12 col-md-4"
                                                            >
                                                                <HostingPlan
                                                                    title={hosting.name}
                                                                    description={hosting.details.description}
                                                                    descriptionClassName={
                                                                        isMobileOnly && "col-6 col-sm-6 col-md-6"
                                                                    }
                                                                    image={
                                                                        hostingPlansImages[
                                                                        hosting.name.toLowerCase()
                                                                        ]
                                                                    }
                                                                    imageWidth="200px"
                                                                    imageHeight="120px"
                                                                    price={pricing.price.value}
                                                                    period={translate(
                                                                        `derivations.${pricing.name.toLowerCase()}`
                                                                    )}
                                                                    anchorLink={"technicalCharacteristics"}
                                                                    currency={pricing.price.currency.code}
                                                                    additional={hosting.details.extra}
                                                                    additionalPrices={hosting.details.prices}
                                                                    newFlag={hosting.details.is_new}
                                                                    newContent={hosting.details.is_new_title}
                                                                    saleFlag={hosting.details.on_sale}
                                                                    saleContent={hosting.details.on_sale_title}
                                                                    features={hosting.details.features}
                                                                    tooltips={hosting.details.tooltips}
                                                                    action={() => (
                                                                        <PrimaryButton
                                                                            size="l"
                                                                            className="w-100"
                                                                            onClick={() =>
                                                                                onShowAssignDomainToHosting(hosting)
                                                                            }
                                                                        >
                                                                            {translate("order-now")}
                                                                        </PrimaryButton>
                                                                    )}
                                                                />
                                                            </div>
                                                        );
                                                    })}
                                            </div>
                                        );
                                    }}
                                </DataContext.Consumer>
                            </HostingPageHostingPlans>

                            <div className="col-12 col-sm-12 col-md-12">
                                <div className="row">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12 col-sm-12 col-md-4">
                                                <Perk
                                                    isMobile={isMobileOnly}
                                                    title={translate("fast-and-optimized-website")}
                                                    description={translate(
                                                        "number-one-configuration-ssd-raid"
                                                    )}
                                                    icon="ranking"
                                                    color="greenBase"
                                                    backgroundColor="green1"
                                                    items={[
                                                        translate(
                                                            "99-percent-quarante-time"
                                                        ),
                                                        translate("1000-views-per-day"),
                                                        translate(
                                                            "servers-connect-to-1gbps-network"
                                                        ),
                                                        translate("serves-in-europe-and-america")
                                                    ]}
                                                />
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-4">
                                                <Perk
                                                    isMobile={isMobileOnly}
                                                    title={translate("professional-email-address")}
                                                    description={translate(
                                                        "create-and-setup-a-professinal-email-that-contains-your-doman,-like-yourname@yourdomain.com"
                                                    )}
                                                    icon="email"
                                                    color="greenBase"
                                                    backgroundColor="green1"
                                                    items={[
                                                        translate("webmail-(access-your-mailbox-through-any-browser)"),
                                                        translate("set-up-you-email-addresses-on-any-smart-phone"),
                                                        activeLanguage && activeLanguage.code === 'mk' ? tutorialTextMk : tutorialTextEN,
                                                        // translate("fast-and-clean-tutorial-for-settuping-mail"),
                                                        translate("smtp,-imap-or-pop3")
                                                    ]}
                                                />
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-4">
                                                <Perk
                                                    isMobile={isMobileOnly}
                                                    title={translate("safe-data-fiirst")}
                                                    description={translate(
                                                        "mkhost-offers-free-regular-backups-of-all-of-your-data,-so-you-never-need-to-worry-about-loosing-any-of-your-emails,-databases-or-websites"
                                                    )}
                                                    icon="privacy"
                                                    color="greenBase"
                                                    backgroundColor="green1"
                                                    items={[
                                                        translate("1-copy-is-kept-on-monthly-basis"),
                                                        translate("three-months-backup"),
                                                        translate(
                                                            "protection-in-the-event-of-a-technical-problem,-natural-disaster-or-hack"
                                                        ),
                                                        translate("completely-free-and-available-at-any-time")
                                                    ]}
                                                />
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-4">
                                                <Perk
                                                    isMobile={isMobileOnly}
                                                    title={translate("authorized-partner-cpanel")}
                                                    description={translate(
                                                        "mkhost-authorized-panel"
                                                    )}
                                                    icon="cpanel"
                                                    color="greenBase"
                                                    backgroundColor="green1"
                                                    items={[
                                                        translate("unique-hosting-settings"),
                                                        translate(
                                                            "installing-app-on-hosting"
                                                        )
                                                    ]}
                                                />
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-4">
                                                <Perk
                                                    isMobile={isMobileOnly}
                                                    title={translate("easy-menagement")}
                                                    description={translate(
                                                        "client-portal-mkhost-available-full-control"
                                                    )}
                                                    icon="setup"
                                                    color="greenBase"
                                                    backgroundColor="green1"
                                                    items={[
                                                        translate("increment-resources"),
                                                        translate(
                                                            "easy-order-for-plus-functionality"
                                                        )
                                                    ]}
                                                />
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-4">
                                                <Perk
                                                    isMobile={isMobileOnly}
                                                    title={translate("security-from-viruses")}
                                                    description={translate(
                                                        "imunify-use"
                                                    )}
                                                    icon="lock"
                                                    color="greenBase"
                                                    backgroundColor="green1"
                                                    items={[
                                                        translate("weekend-scan"),
                                                        translate(
                                                            "notification-for-virus-detected"
                                                        )
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-12 col-md-12">
                                        <div className="row">
                                            <div className="container">
                                                <StypedTop className="row">
                                                    <div className="col-12 col-sm-12 col-md-8">

                                                        <div id="technicalCharacteristics" style={{ "margin": "100px 0" }}>&nbsp;</div>
                                                        <SectionTitle>
                                                            {translate("technical-characteristics-of-hosting-plans-web")}
                                                        </SectionTitle>
                                                    </div>
                                                </StypedTop>


                                                <div style={{ "margin": "100px 0" }}>&nbsp;</div>

                                                <FeaturesTableSection
                                                    className="row no-gutters"
                                                    isMobile={isMobileOnly}
                                                >
                                                    <div className="col-12 col-sm-12 col-md-12">
                                                        <div className="row no-gutters">
                                                            <div className="container">
                                                                <div className="row no-gutters">
                                                                    <div className="col-12 col-sm-12 col-md-3" />
                                                                    <div className="col-12 col-sm-12 col-md-9">
                                                                        <div className="row no-gutters">
                                                                            <div
                                                                                className="col-12 col-sm-12 col-md-4"
                                                                                key={`ssl-${Math.random()}`}
                                                                            >
                                                                                <SupportPlan
                                                                                    firstChild={true}
                                                                                    className={'text-center'}
                                                                                    title={"Basic"}
                                                                                />
                                                                                {isMobileOnly && (
                                                                                    <FeaturesTable
                                                                                        className="features-table row no-gutters">
                                                                                        <div
                                                                                            className="col-12 col-sm-12 col-md-12">
                                                                                            {features.map(
                                                                                                (feature) => (
                                                                                                    <div
                                                                                                        key={`feature-ssl-${Math.random()}`}
                                                                                                        className="row no-gutters feature-row"
                                                                                                    >
                                                                                                        <div
                                                                                                            className="col-12 col-sm-12 col-md-9">
                                                                                                            <div
                                                                                                                className="row no-gutters">
                                                                                                                <div
                                                                                                                    className="col-12 col-sm-12 col-md-4">

                                                                                                                    {activeLanguage &&
                                                                                                                        (
                                                                                                                            <div
                                                                                                                                className="feature-value text-center">
                                                                                                                                {feature.name[activeLanguage.code]}
                                                                                                                                {feature.translate ?
                                                                                                                                    (
                                                                                                                                        <div>
                                                                                                                                            {feature["Basic"][activeLanguage.code]}
                                                                                                                                        </div>
                                                                                                                                    )
                                                                                                                                    : (
                                                                                                                                        <div>
                                                                                                                                            {feature["Basic"] === true ?
                                                                                                                                                <StyledAvaiable
                                                                                                                                                    src={check_success} /> : parse(feature["Basic"])}
                                                                                                                                        </div>)

                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        )
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            )}
                                                                                        </div>
                                                                                    </FeaturesTable>
                                                                                )}
                                                                            </div>
                                                                            <div
                                                                                className="col-12 col-sm-12 col-md-4"
                                                                                key={`ssl-${Math.random()}`}
                                                                            >
                                                                                <SupportPlan
                                                                                    className={'text-center'}
                                                                                    firstChild={false}
                                                                                    title={"Regular"}
                                                                                />
                                                                                {isMobileOnly && (
                                                                                    <FeaturesTable
                                                                                        className="features-table row no-gutters">
                                                                                        <div
                                                                                            className="col-12 col-sm-12 col-md-12">
                                                                                            {features.map(
                                                                                                (feature) => (
                                                                                                    <div
                                                                                                        key={`feature-ssl-${Math.random()}`}
                                                                                                        className="row no-gutters feature-row"
                                                                                                    >
                                                                                                        <div
                                                                                                            className="col-12 col-sm-12 col-md-9">
                                                                                                            <div
                                                                                                                className="row no-gutters">
                                                                                                                <div
                                                                                                                    className="col-12 col-sm-12 col-md-4">

                                                                                                                    {activeLanguage &&
                                                                                                                        (
                                                                                                                            <div
                                                                                                                                className="feature-value text-center">
                                                                                                                                {feature.name[activeLanguage.code]}
                                                                                                                                {feature.translate ?
                                                                                                                                    (
                                                                                                                                        <div>
                                                                                                                                            {feature["Regular"][activeLanguage.code]}
                                                                                                                                        </div>
                                                                                                                                    )
                                                                                                                                    : (
                                                                                                                                        <div>
                                                                                                                                            {feature["Regular"] === true ?
                                                                                                                                                <StyledAvaiable
                                                                                                                                                    src={check_success} /> : parse(feature["Regular"])}
                                                                                                                                        </div>)

                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        )
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            )}
                                                                                        </div>
                                                                                    </FeaturesTable>
                                                                                )}
                                                                            </div>
                                                                            <div
                                                                                className="col-12 col-sm-12 col-md-4"
                                                                                key={`ssl-${Math.random()}`}
                                                                            >
                                                                                <SupportPlan
                                                                                    firstChild={false}
                                                                                    className={'text-center'}
                                                                                    title={"Power"}
                                                                                />
                                                                                {isMobileOnly && (
                                                                                    <FeaturesTable
                                                                                        className="features-table row no-gutters">
                                                                                        <div
                                                                                            className="col-12 col-sm-12 col-md-12">
                                                                                            {features.map(
                                                                                                (feature) => (
                                                                                                    <div
                                                                                                        key={`feature-ssl-${Math.random()}`}
                                                                                                        className="row no-gutters feature-row"
                                                                                                    >
                                                                                                        <div
                                                                                                            className="col-12 col-sm-12 col-md-9">
                                                                                                            <div
                                                                                                                className="row no-gutters">
                                                                                                                <div
                                                                                                                    className="col-12 col-sm-12 col-md-4">

                                                                                                                    {activeLanguage &&
                                                                                                                        (
                                                                                                                            <div
                                                                                                                                className="feature-value text-center">
                                                                                                                                {feature.name[activeLanguage.code]}
                                                                                                                                {feature.translate ?
                                                                                                                                    (
                                                                                                                                        <div>
                                                                                                                                            {feature["Power"][activeLanguage.code]}
                                                                                                                                        </div>
                                                                                                                                    )
                                                                                                                                    : (
                                                                                                                                        <div>
                                                                                                                                            {feature["Power"] === true ?
                                                                                                                                                <StyledAvaiable
                                                                                                                                                    src={check_success} /> : parse(feature["Power"])}
                                                                                                                                        </div>)
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        )
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            )}
                                                                                        </div>
                                                                                    </FeaturesTable>
                                                                                )}
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {!isMobileOnly && (
                                                                    <FeaturesTable className="row no-gutters">
                                                                        <div className="col-12 col-sm-12 col-md-12">
                                                                            {features.map(
                                                                                (feature) => (
                                                                                    <div
                                                                                        key={`feature-ssl-${Math.random()}`}
                                                                                        className="row no-gutters feature-row"
                                                                                    >
                                                                                        <div
                                                                                            className="col-12 col-sm-12 col-md-3 feature-wrapper">
                                                                                            <div className="feature">
                                                                                                {activeLanguage &&
                                                                                                    (feature.name[activeLanguage.code])
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-12 col-sm-12 col-md-9">
                                                                                            <div
                                                                                                className="row no-gutters">
                                                                                                {feature.translate ?
                                                                                                    (
                                                                                                        <>
                                                                                                            <div
                                                                                                                className="col-12 col-sm-12 col-md-4 feature-value-wrapper"
                                                                                                                key={`ssl-feature-${Math.random()}`}
                                                                                                            >
                                                                                                                <div
                                                                                                                    className="feature-value text-center">
                                                                                                                    {activeLanguage &&
                                                                                                                        parse(feature["Basic"][activeLanguage.code])
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="col-12 col-sm-12 col-md-4 feature-value-wrapper"
                                                                                                                key={`ssl-feature-${Math.random()}`}
                                                                                                            >
                                                                                                                <div
                                                                                                                    className="feature-value text-center">
                                                                                                                    {activeLanguage &&
                                                                                                                        parse(feature["Regular"][activeLanguage.code])
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="col-12 col-sm-12 col-md-4 feature-value-wrapper"
                                                                                                                key={`ssl-feature-${Math.random()}`}
                                                                                                            >
                                                                                                                <div
                                                                                                                    className="feature-value text-center">
                                                                                                                    {activeLanguage &&
                                                                                                                        parse(feature["Power"][activeLanguage.code])
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            <div
                                                                                                                className="col-12 col-sm-12 col-md-4 feature-value-wrapper"
                                                                                                                key={`ssl-feature-${Math.random()}`}
                                                                                                            >
                                                                                                                <div
                                                                                                                    className="feature-value text-center">
                                                                                                                    {feature["Basic"] === true ?
                                                                                                                        <StyledAvaiable
                                                                                                                            src={check_success} /> : parse(feature["Basic"])}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="col-12 col-sm-12 col-md-4 feature-value-wrapper"
                                                                                                                key={`ssl-feature-${Math.random()}`}
                                                                                                            >
                                                                                                                <div
                                                                                                                    className="feature-value text-center">
                                                                                                                    {feature["Regular"] === true ?
                                                                                                                        <StyledAvaiable
                                                                                                                            src={check_success} /> : parse(feature["Regular"])}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="col-12 col-sm-12 col-md-4 feature-value-wrapper"
                                                                                                                key={`ssl-feature-${Math.random()}`}
                                                                                                            >
                                                                                                                <div
                                                                                                                    className="feature-value text-center">
                                                                                                                    {feature["Power"] === true ?
                                                                                                                        <StyledAvaiable
                                                                                                                            src={check_success} /> : parse(feature["Power"])}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </>
                                                                                                    )}


                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            )}
                                                                        </div>
                                                                    </FeaturesTable>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </FeaturesTableSection>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12 col-sm-12 col-md-8">

                                                <div style={{ "margin": "100px 0" }}>&nbsp;</div>
                                                <SectionTitle>
                                                    {translate("need-more-functionalities")}

                                                </SectionTitle>
                                                <SectionDescription>
                                                    {translate(
                                                        "see-more-hosting-plans-related"
                                                    )}
                                                </SectionDescription>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-6">
                                                <StyledContainer>
                                                    <SmallTitle>
                                                        {translate("wp-hosting")}
                                                    </SmallTitle>
                                                    <StyledSmallDescription>
                                                        {translate(
                                                            "the-most-popular-hosting-for-many-web-solutions"
                                                        )}
                                                    </StyledSmallDescription>
                                                    <div className="row">
                                                        <div className="col-12 col-sm-12 col-md-6 ">
                                                            <Link to={ROUTE_HOSTING_WORDPRESS_HOSTING} target="_blank">
                                                                <PrimaryButton
                                                                    className={isMobileOnly && "btn-block mt-2"}
                                                                >
                                                                    {translate("read-more")}
                                                                </PrimaryButton>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </StyledContainer>
                                            </div>

                                            <div className="col-12 col-sm-12 col-md-6">
                                                <StyledContainer>
                                                    <SmallTitle>
                                                        {translate("win-hosting")}
                                                    </SmallTitle>
                                                    <StyledSmallDescription>
                                                        {translate("advanced-options-for-web-soliution")}
                                                    </StyledSmallDescription>
                                                    <div class="row">
                                                        <div className="col-12 col-sm-12 col-md-6 ">
                                                            <Link to={ROUTE_HOSTING_WINDOWS_HOSTING} target="_blank">
                                                                <PrimaryButton
                                                                    className={isMobileOnly && "btn-block mt-2"}
                                                                >
                                                                    {translate("read-more")}
                                                                </PrimaryButton>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </StyledContainer>
                                            </div>

                                            <div className="col-12 col-sm-12 col-md-6">
                                                <StyledContainer>
                                                    <SmallTitle>
                                                        {translate("bussiness-hosting-pack")}
                                                    </SmallTitle>
                                                    <StyledSmallDescription>
                                                        {translate("for-companies-who-need-big-space")}
                                                    </StyledSmallDescription>
                                                    <div className="row">

                                                        <div className="col-12 col-sm-12 col-md-6 ">
                                                            <Link to={ROUTE_HOSTING_BUSINESS_HOSTING} target="_blank">
                                                                <PrimaryButton
                                                                    className={isMobileOnly && "btn-block mt-2"}
                                                                >
                                                                    {translate("read-more")}
                                                                </PrimaryButton>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </StyledContainer>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-6">
                                                <StyledContainer>
                                                    <SmallTitle>
                                                        {translate("cloud-servers")}
                                                    </SmallTitle>
                                                    <StyledSmallDescription>
                                                        {translate(
                                                            "ideal-for-fast-grow-web-solutions"
                                                        )}
                                                    </StyledSmallDescription>
                                                    <div className="row">
                                                        <div className="col-12 col-sm-12 col-md-6 ">
                                                            <Link to={ROUTE_SERVERS_CLOUD} target="_blank" >
                                                                <PrimaryButton
                                                                    className={isMobileOnly && "btn-block mt-2"}
                                                                >
                                                                    {translate("read-more")}
                                                                </PrimaryButton>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </StyledContainer>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            {/*<WhyHostWithMKhostSection />*/}

                            <SupportTypesSection />
                            <PremiumPackageSection />
                            <OneClickAppInstallBlocksSection />

                            <MostPopularArticlesSection faqCategory={FAQ_CATEGORY_HOSTING} />
                        </div>

                        {this.state.showDomainModal && (
                            <Modal
                                title={translate("add-your-domain-to-your-hosting")}
                                onCloseModal={this.onCloseModal}
                                body={() => (
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-8">
                                            <InputField
                                                name="domain"
                                                value={this.state.domain.toLowerCase()}
                                                onChange={this.onChangeDomain}
                                                placeholder={translate("domain-name")}
                                                label={translate("domain")}
                                            />
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-4">
                                            <SecondaryButton
                                                className="mt-4"
                                                disabled={
                                                    !this.state.domain.length || this.state.submitting
                                                }
                                                submitting={this.state.submitting}
                                                onClick={() => this.onAddToCart()}
                                            >
                                                {translate("assign-&-add")}
                                            </SecondaryButton>
                                        </div>
                                    </div>
                                )}
                            />
                        )}

                        {this.state.showSubscribeFreeEbookModal && (
                            <SubscribeFreeEbookModal
                                onCloseModal={this.onHideSubscribeFreeEbookModal}
                            />
                        )}


                    </StyledHostingWebPage>

                )}

            </Translate>

        );
    }
}

export default withRouter(HostingWebPage);

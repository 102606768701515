import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";

import { NavLink } from "react-router-dom";

const StyledDropdownNavigationGroup = styled.div`
  display: inline-block;
  width: 22%;
  margin-right: 30px;
  vertical-align: top;
  min-height: 150px;
`;

const StyledTitle = styled.span`
  display: block;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${props => props.theme.neutral4};
  margin-bottom: 20px;
`;

const StyledLink = styled(NavLink)`
  display: block;
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.56;
  color: ${props => props.theme.neutralBase};
  margin-bottom: 20px;
  cursor: pointer;
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    text-decoration: none;
  }
`;

const StyledAnchor = styled.a`
  display: block;
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.56;
  color: ${props => props.theme.neutralBase};
  margin-bottom: 20px;
  cursor: pointer;
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    text-decoration: none;
  }
`;

const StyledLinkDescription = styled.div`
  margin-top: 5px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.43;
  color: ${props => props.theme.neutral6};
`;

const StyledHiddenPlaceholder = styled.span`
  display: block;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${props => props.theme.neutral4};
  margin-bottom: 20px;
  visibility: hidden;
`;

const DropdownNavigationGroupItems = ({ groups }) => {
  return (
    <Translate>
      {({ translate,activeLanguage }) => (
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12">
            {groups.map(group => (
              <StyledDropdownNavigationGroup
                key={`menu-group-${Math.random()}`}
              >
                <StyledTitle>
                  {group.title.length > 0 ? (
                    translate(group.title)
                  ) : (
                    <StyledHiddenPlaceholder className="invisible">
                      "menu"
                    </StyledHiddenPlaceholder>
                  )}
                </StyledTitle>
                {group.routes.map(
                  (route, index) => {
                    if (activeLanguage && route.hideOn !== activeLanguage.code) {
                      return (route.path ? (
                              <StyledLink
                                  to={
                                    route.path.indexOf(":") > -1
                                        ? route.path.split(":")[0]
                                        : route.path
                                  }
                                  key={`menu-group-item-${route.path}`}
                              >
                                {translate(route.title)}
                                <StyledLinkDescription>
                                  {translate(route.description)}
                                </StyledLinkDescription>
                              </StyledLink>
                          ) : (
                              <StyledAnchor
                                  key={`menu-group-item-${route.path}`}
                                  href={route.link}
                                  target="_blank"
                                  rel="noopenner nofollow"
                              >
                                {translate(route.title)}
                                <StyledLinkDescription>
                                  {translate(route.description)}
                                </StyledLinkDescription>
                              </StyledAnchor>
                          )
                      )
                    }
                  })
                   }
              </StyledDropdownNavigationGroup>
            ))}
          </div>
        </div>
      )}
    </Translate>
  );
};

export default DropdownNavigationGroupItems;

import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

import { ROUTE_WEB_DESIGN_PREMIUM } from "../../../routes";

import SectionTitle from "../../SectionTitle";
import SectionDescription from "../../SectionDescription";
import DomainsSearchSection from "../../Sections/DomainsSearchSection";
import ProjectQuoteFormSection from "../../Sections/ProjectQuoteFormSection";
import PrimaryButton from "../../Buttons/PrimaryButton";

const StyledWebsiteBuildSection = styled.div`
  padding: 75px 0;
`;

const StyledCircle = styled.div`
  display: block;
  margin-bottom: 30px;
  padding-top: 15px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  font-size: 34px;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  background-color: ${props => props.theme.blueBase};
  color: ${props => props.theme.white};
`;

const StyledBoldDescription = styled(SectionDescription)`
  font-weight: 600;
  margin-top: 20px;
`;

const WebsiteBuildSection = () => (
  <Translate>
    {({ translate }) => (
      <StyledWebsiteBuildSection className="row">
        <div className="col-12 col-sm-12 col-md-12">
          <div className="row">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12">
                  <StyledCircle>3</StyledCircle>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-5">
                  <SectionTitle>
                    {translate("each-website-needs-to-be-built")}
                  </SectionTitle>
                  <SectionDescription>
                    {translate(
                      "once-the-location-and-space-have-been-defined,-the-next-step-is-to-arrange-the-home-according-to-the-wishes-of-its-owner"
                    )}
                    .
                  </SectionDescription>

                  <SectionDescription>
                    {parse(
                      translate(
                        "the-most-important-part-of-a-website-is-its-design!-with-our-premium-package,-it's-never-been-easier-to-have-a-perfectly-designed-website"
                      )
                    )}
                  </SectionDescription>
                  <a
                    href={ROUTE_WEB_DESIGN_PREMIUM}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <PrimaryButton>
                      {translate("view-premium-options")}
                    </PrimaryButton>
                  </a>

                  {isMobileOnly && (
                    <div className="text-center mt-5">
                      <img
                        width="60%"
                        className="img-fluid"
                        src="/assets/icons/icon_illustration_design.png"
                        alt=""
                      />
                    </div>
                  )}
                </div>
                {!isMobileOnly && (
                  <div className="col-12 col-sm-12 col-md-6 offset-md-1 text-center">
                    <img
                      width="60%"
                      className="img-fluid"
                      src="/assets/icons/icon_illustration_design.png"
                      alt=""
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </StyledWebsiteBuildSection>
    )}
  </Translate>
);
export default WebsiteBuildSection;

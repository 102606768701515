import React from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import { withLocalize } from "react-localize-redux";

import DataContext from "../../../DataContext";

import * as domainsService from "../../../services/domainsService";
import {
  add,
  storeCartId,
  applyPromotionCode,
  default as cartService,
  getCartId
} from "../../../services/cartService";

import {
  validateDomain,
  validateOnlyDomainName,
  validateExtension
} from "../../../common/validationRules";

import displayToastMessageForResponse from "../../../utils/displayToastMessageForResponse";

import DomainSearch from "../../../components/DomainSearch";
import DomainExtensionsQuickPrices from "../../../components/DomainExtensionsQuickPrices";
import DomainSearchResults from "../../../components/DomainSearch/DomainSearchResults";

import HostingPromotion from "../../../components/HostingPromotion";
import Modal from "../../Modal";

import { ROUTE_CLIENT_CART } from "../../../routes";

import {
  DOMAIN_STATUS_AVAILABLE,
  DOMAIN_STATUS_UNAVAILABLE
} from "../../../pages/Domains/DomainStatuses";

import {
  REACT_APP_MKHOST_MK_DOMAINS_PROMOTION_DISCOUNT_TYPE,
  REACT_APP_MKHOST_MK_DOMAINS_PROMOTION_DISCOUNT_VALUE
} from "../../../config/promotions";
import OtherAvailableDomains from "../../OtherAvailableDomains";

const DomainSearchWrapper = styled.div`
  position: relative;
  //background-color: ${props => props.theme.blue1};
  & .domain-search {
    position: relative;
    top: -55px;
  }

  & .domain-extensions-quick-prices {
    position: relative;
    top: -25px;
  }
`;

const StyledResultsWrapper = styled.div`
  background-color: ${props => props.theme.blue1};
`;

const homepageDomains = ["mk", "com", "com.mk", "net", "info", "edu.mk", "org"];
const mobileHomepageDomains = ["mk", "com", "com.mk", "net"];

const validate = values => {
  return {
    domain: validateOnlyDomainName(values.domain, values.extension.value),
    extension: validateExtension(values.extension.value)
  };
};

class DomainSearchSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tld: null,
      currency: null,
      extensions: null,
      quickPrices: null,
      searchDomain: null,
      domain: null,
      selectedExtension: this.props.selectedExtension || null,
      addedToCart: false,
      submitting: false,
      searching: false,
      whoIs: null,
      error: false,
      submittingAdditional: false,
      addedAdditionalDomains: [],
      showHostingPromotionModal: false,
      submittingHostingPromotion: false
    };
    this.quoteRef = null;
  }

  componentDidMount() {
    domainsService.fetchDomainsPrices().then(result => {
      const { tld } = result.data.data;

      let extensions = [];
      let currency =
        this.props.activeLanguage && this.props.activeLanguage.code === "en"
          ? "eur"
          : "мкд";
      let quickPrices = [];

      const domains = isMobileOnly ? mobileHomepageDomains : homepageDomains;

      Object.keys(tld).map(ext => {
        extensions.push({ label: `.${ext}`, value: ext });
        if (domains.indexOf(ext) > -1) {
          quickPrices.push(
            `.${ext} ${
              isMobileOnly ? "<br />" : ""
            } ${currency.toUpperCase()} ${parseFloat(
              tld[ext][currency].register[1]
            ).toFixed(currency === "eur" ? 2 : 0)}`
          );
        }
        return ext;
      });

      this.setState(
        {
          tld,
          currency,
          extensions,
          quickPrices
        },
        () => {
          if (this.props.domain && this.props.selectedExtension) {
            document
              .getElementById("domain-search-form")
              .dispatchEvent(new Event("submit", { cancelable: true }));
          }
        }
      );
    });
  }

  onSearchDomain = values => {
    this.setState({ searching: true });
    return domainsService
      .isDomainAvailable(values.domain + values.extension.label)
      .then(result => {
        const { domain } = result.data.data;
        const { error } = result.data;

        if (
          !values.extension.label.endsWith("mk") &&
          !values.extension.label.endsWith("мкд")
        ) {
          this.setState(
            {
              domain: domain,
              searchDomain: values.domain + values.extension.label,
              selectedExtension: values.extension.label,
              whoIs: null,
              searching: false,
              addedToCart: false,
              error: error
            },
            () => {
              if (this.quoteRef) {
                this.quoteRef.scrollIntoView({
                  behavior: "smooth",
                  block: "start"
                });
              }
            }
          );
          return result;
        } else {
          if (domain.status === DOMAIN_STATUS_AVAILABLE) {
            this.setState(
              {
                domain: domain,
                searchDomain: values.domain + values.extension.label,
                selectedExtension: values.extension.label,
                whoIs: null,
                searching: false,
                addedToCart: false,
                error: error
              },
              () => {
                if (this.quoteRef) {
                  this.quoteRef.scrollIntoView({
                    behavior: "smooth",
                    block: "start"
                  });
                }
              }
            );
            return result;
          } else if (domain.status === DOMAIN_STATUS_UNAVAILABLE) {
            return domainsService.domainWhoIs(domain.name).then(result => {
              this.setState(
                {
                  domain: domain,
                  searchDomain: values.domain + values.extension.label,
                  selectedExtension: values.extension.label,
                  whoIs: result.data.data,
                  searching: false,
                  addedToCart: false,
                  error: error
                },
                () => {
                  if (this.quoteRef) {
                    this.quoteRef.scrollIntoView({
                      behavior: "smooth",
                      block: "start"
                    });
                  }
                }
              );
              return result;
            });
          }
        }
      })
      .catch(errorResult => {
        const { error } = errorResult.response.data;
        this.setState({
          domain: {
            name: values.domain + values.extension.label,
            status: DOMAIN_STATUS_UNAVAILABLE
          },
          searchDomain: values.domain + values.extension.label,
          whoIs: null,
          searching: false,
          error: error
        });
      });
  };

  onAddToCart = (domain, type, period = null, additionalDomain = false) => {
    this.setState({
      submitting: additionalDomain ? false : true,
      addedToCart: false,
      submittingAdditional: additionalDomain ? true : false
    });
    if (
      this.props.onAddToCart &&
      typeof this.props.onAddToCart === "function"
    ) {
      return this.props.onAddToCart(domain, type);
    }
    return add({
      type: "domain",
      domain_id: null,
      domain: domain.name,
      quantity: 1,
      config: {
        type: type || "register",
        period: period
          ? period
          : domain.pricing[type || "register"].billing_cycle.derivations[0]
              .numeric_code
      }
    })
      .then(response => {
        const { error } = response.data;
        const { cart, messages } = response.data.data;
        storeCartId(cart.id);
        const data = { error, messages };
        displayToastMessageForResponse(
          this.props.translate("general.shop"),
          data,
          `${ROUTE_CLIENT_CART}?cart=${cart.id}`,
          this.props.translate("general.view-cart")
        );

        this.setState({
          submitting: false,
          addedToCart: additionalDomain ? false : true,
          submittingAdditional: false,
          addedAdditionalDomains: this.state.addedAdditionalDomains.concat([
            domain.name
          ]),
          showHostingPromotionModal: true
        });

        document.addEventListener("click", this.handleOutsideClick, false);
      })
      .catch(err => {
        const { error } = err.response.data;
        const { cart, messages } = err.response.data.data;

        const data = { error, messages };
        displayToastMessageForResponse(
          this.props.translate("general.shop"),
          data,
          `${ROUTE_CLIENT_CART}?cart=${cart.id}`,
          this.props.translate("general.view-cart")
        );
        this.setState({
          submitting: false,
          addedToCart: false,
          submittingAdditional: false,
          addedAdditionalDomains: this.state.addedAdditionalDomains.concat([
            domain.name
          ]),
          showHostingPromotionModal: false
        });

        document.removeEventListener("click", this.handleOutsideClick, false);
      });
  };

  onAddHostingToCart = hosting => {
    this.setState({
      submittingHostingPromotion: true
    });
    return add({
      cart: this.props.cart ? this.props.cart.id : null,
      type: "hosting",
      quantity: 1,
      config: {
        type: "buy",
        period: hosting.pricing.buy.billing_cycle.derivations[0].numeric_code
      },
      domain: hosting.domain,
      hosting_id: hosting.id
    }).then(response => {
      const { error } = response.data;
      const { cart, messages } = response.data.data;
      storeCartId(cart.id);
      if (this.props.type === "register") {
        // return applyPromotionCode("SUMMER20OFF")
        //   .then(
        //     () => {
        //       window.location.replace(
        //         `${ROUTE_CLIENT_CART}?cart=${this.props.cart.id}`
        //       );
        //     },
        //     err => {
        //       window.location.replace(
        //         `${ROUTE_CLIENT_CART}?cart=${getCartId()}`
        //       );
        //     }
        //   )
        //   .catch(err => {
        //     window.location.replace(`${ROUTE_CLIENT_CART}?cart=${getCartId()}`);
        //   });
        window.location.replace(
          `${ROUTE_CLIENT_CART}?cart=${
            this.props.cart ? this.props.cart.id : getCartId()
          }`
        );
      } else if (this.props.type === "transfer") {
        // return applyPromotionCode("TRANSFERHOSTING50OFF")
        //   .then(
        //     () => {
        //       window.location.replace(
        //         `${ROUTE_CLIENT_CART}?cart=${this.props.cart.id}`
        //       );
        //     },
        //     err => {
        //       window.location.replace(
        //         `${ROUTE_CLIENT_CART}?cart=${getCartId()}`
        //       );
        //     }
        //   )
        //   .catch(err => {
        //     window.location.replace(`${ROUTE_CLIENT_CART}?cart=${getCartId()}`);
        //   });
        window.location.replace(
          `${ROUTE_CLIENT_CART}?cart=${
            this.props.cart ? this.props.cart.id : getCartId()
          }`
        );
      } else {
        window.location.replace(
          `${ROUTE_CLIENT_CART}?cart=${this.props.cart.id}`
        );
      }
    });
  };

  onHideHostingPromotionModal = () => {
    this.setState({
      showHostingPromotionModal: false
    });

    document.removeEventListener("click", this.handleOutsideClick, false);
  };

  //

  checkForParentId = (element, searchId) => {
    if ( !element.parentElement ) {
      return false;
    }
    
    if ( element.id === searchId ) {
      return true;
    }
    
    return element.parentElement && this.checkForParentId(element.parentElement, searchId);
  };

  handleOutsideClick = e => {
       if ( !this.checkForParentId(e.target, 'modal-container') )
       {
          if( this.checkForParentId(e.target, 'modal-root') )
          { 
            this.setState(prevState => ({
              showHostingPromotionModal: false
            }));
          }
       }
  };

  //

  render() {
    return (
      <div className="row domains-search-section">
        <DomainSearchWrapper
          className="col-12 col-sm-12 col-md-12 domain-section-search-wrapper"
          ref={ref => (this.quoteRef = ref)}
        >
          <div className="row">
            <div className="container">
              {this.state.extensions && (
                <DomainSearch
                  onSubmit={this.onSearchDomain}
                  validate={validate}
                  extensions={this.state.extensions}
                  domain={this.props.domain}
                  selectedExtension={
                    this.props.selectedExtension &&
                    this.props.selectedExtension.value
                      ? this.props.selectedExtension.value
                      : this.props.selectedExtension
                  }
                  searchPlaceholder={this.props.searchPlaceholder}
                  searching={this.state.searching}
                />
              )}
              {!this.props.hideQuickPrices &&
                this.state.quickPrices &&
                !this.props.quickPrices && (
                  <DomainExtensionsQuickPrices
                    prices={this.state.quickPrices}
                  />
                )}
              {!this.props.hideQuickPrices &&
                !this.state.quickPrices &&
                this.props.quickPrices && (
                  <DomainExtensionsQuickPrices
                    prices={this.props.quickPrices}
                  />
                )}
              {!this.props.hideQuickPrices &&
                this.props.quickPrices &&
                this.state.quickPrices && (
                  <DomainExtensionsQuickPrices
                    prices={this.props.quickPrices}
                  />
                )}
            </div>
          </div>
        </DomainSearchWrapper>
        <StyledResultsWrapper className="col-12 col-sm-12 col-md-12 domain-search-section-results">
          <div className="row">
            <div className="container">
              {this.state.domain && (
                <DataContext.Consumer>
                  {({
                    onFetchCartInfo,
                    domains,
                    extensions,
                    domainGroups,
                    currency,
                    hostings
                  }) => (
                    <>
                      <DomainSearchResults
                        domain={this.state.domain}
                        search={this.state.searchDomain}
                        onAddToCart={(domain, type) =>
                          this.onAddToCart(domain, type).then(() =>
                            onFetchCartInfo()
                          )
                        }
                        addedToCart={this.state.addedToCart}
                        submitting={this.state.submitting}
                        type={this.props.type}
                        whoIs={this.state.whoIs}
                        error={this.state.error}
                        priceReduce={
                          this.state.selectedExtension === ".mk"
                            ? {
                                type: "register",
                                discountType: REACT_APP_MKHOST_MK_DOMAINS_PROMOTION_DISCOUNT_TYPE,
                                value: REACT_APP_MKHOST_MK_DOMAINS_PROMOTION_DISCOUNT_VALUE
                              }
                            : null
                        }
                      />
                      {this.state.domain &&
                        this.props.type === "register" &&
                        this.state.selectedExtension !== ".мкд" && (
                          <OtherAvailableDomains
                            domain={this.state.searchDomain}
                            extension={this.state.selectedExtension}
                            onAddToCart={(
                              domain,
                              type,
                              period = null,
                              additionalDomain = false
                            ) =>
                              this.onAddToCart(
                                domain,
                                type,
                                period,
                                additionalDomain
                              ).then(() => onFetchCartInfo())
                            }
                            extensions={extensions}
                            priceReduce={{
                              type: "register",
                              discountType: REACT_APP_MKHOST_MK_DOMAINS_PROMOTION_DISCOUNT_TYPE,
                              value: REACT_APP_MKHOST_MK_DOMAINS_PROMOTION_DISCOUNT_VALUE
                            }}
                            priceReduceExtensions={[".mk"]}
                            topLevelDomainGroups={domainGroups}
                            topLevelDomainsListingByGroup={domains}
                            customerCurrencyCode={currency}
                            submitting={this.state.submittingAdditional}
                            addedAdditionalDomains={
                              this.state.addedAdditionalDomains
                            }
                          />
                        )}

                      {hostings && this.state.showHostingPromotionModal && (
                        <Modal
                          title={
                            this.props.type === "register"
                              ? this.props.translate(
                                  "sections.hosting-promotion.default-title"  
                                  // "sections.hosting-promotion.buy-hosting-with-register-discount"
                                )
                              : this.props.translate(
                                  "sections.hosting-promotion.default-title"
                                  // "sections.hosting-promotion.buy-hosting-with-transfer-discount"
                                )
                          }
                          size="xl"
                          onCloseModal={this.onHideHostingPromotionModal}
                          body={() => (
                            <HostingPromotion
                              hostings={hostings}
                              domain={this.state.searchDomain}
                              domainType={this.props.type}
                              currency={currency}
                              onAddToCart={this.onAddHostingToCart}
                              submitting={this.state.submittingHostingPromotion}
                            />
                          )}
                        />
                      )}
                    </>
                  )}
                </DataContext.Consumer>
              )}
            </div>
          </div>
        </StyledResultsWrapper>
      </div>
    );
  }
}

export default withRouter(withLocalize(DomainSearchSection));

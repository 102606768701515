export default {
  mk: {
    homepage: {
      title: "Веб хостинг и регистрација на .mk домени - МКхост",
      meta_keywords: "мкхост, веб хостинг, домени, mk hosting, mk domain",
      meta_description:
        "МКхост е најголем македонски веб хостинг провајдер и регистрар за .mk домени. Хостирај го твојот сајт во МКхост и добиј професионална поддршка 24/7."
    },
    domains_register: {
      title: "Регистрирај домен - Најголем регистрар на .mk домени - МКхост",
      meta_keywords: "домен, мк домени, регистрација на домени",
      meta_description:
        "Пребарај го твојот омилен домен и регистрирај го веднаш. Инстант активација за 5 минути на македонски (.mk) и интернационални домени. 24/7 достапна техничка поддршка."
    },
    domains_tlds: {
      title: "nTLD - Нови домен наставки - МКхост",
      meta_keywords: "тренд домени, nTLD, нови домени, new top level domains",
      meta_description:
        "Имаш специфичен бизнис кој сакаш да го издвоиш од останатите? Регистрирај нови домен наставки (nTLD) за специфични намени во само неколку чекори."
    },
    domains_transfer: {
      title: "Направи бесплатен трансфер на .mk домен - МКхост",
      meta_keywords: "трансфер на .mk домен, миграција на домен",
      meta_description:
        "Бесплатен трансфер на .mk домени. Приклучи се кон најголемиот македонски регистрар на домени и префрли го твојот домен во МКхост во неколку чекори."
    },
    domains_prices: {
      title: "Цени за домени - .mk и интернационални домени - МКхост",
      meta_keywords: "цена на домени, регистрација на домени",
      meta_description:
        "Приклучи се на најголемиот македонски регистрар за домени. Провери ги цените за регистрација, обнова и трансферирање на сите домен наставки - .mk, .com и останати."
    },
    domains_bulk: {
      title: "Закупи поголем број на домени - Добиј попуст - МКхост",
      meta_keywords: "закупување домени, попуст на домени, мк домени",
      meta_description:
        "Сакаш да регистрираш повеќе домени во МКхост? Нудиме повеќе бенефити и дополнителна корисничка поддршка во зависност од твоите потреби."
    },
    domains_whois: {
      title: "WHOIS - Проверка на сопственост на .mk домени - МКхост",
      meta_keywords: "whois, сопственост на домен, проверка на домени",
      meta_description:
        "Сакаш да провериш дали одреден .mk домен е слободен или зафатен? WHOIS сервисот овозможува да ги провериш корисничките информации за сите македонски домени."
    },
    hosting_web_hosting: {
      title: "Веб хостинг - Хостирај го твојот веб сајт во МКхост",
      meta_keywords:
        "веб хостинг, web hosting, хостинг провајдер, linux hosting, линукс хостинг",
      meta_description:
          "Ти треба основен веб сајт, или пак можеби голем веб проект? МКхост нуди брзи и сигурни Linux веб хостинг планови со врвна техничка поддршка."
    },
    other_web_solution: {
      title: "Да ти помогнеме во твојот онлајн бизнис!",
      meta_keywords:
          "веб хостинг, web hosting, хостинг провајдер,веб бизнис, онлајн бизнис,веб дизајн,web design",
      meta_description:
          "МКхост сака да ги поддржи младите креативци кои што продаваат свои изработки на социјалните мрежи, со тоа што би добиле професионална продавница каде што ќе може да ги пласираат своите продукти. Еден најсреќен добитник ќе добие целосно веб решение."
    },
    hosting_business_hosting: {
      title: "Бизнис хостинг - Премиум опции за твојот веб сајт - МКхост",
      meta_keywords:
        "бизнис хостинг, професионални емаил адреси, премиум поддршка",
      meta_description:
        "Брз и сигурен хостинг простор за твојот бизнис. Одбери го идеалниот хостинг план и добиј професионални емаил адреси и премиум поддршка достапна 24/7."
    },
    online_shop_promotion:{
      title: "Направи целосна онлајн продавница",
      meta_keywords:
          "онлајн,продавница,продажба,онлајн наплата",
      meta_description:
          "Сакаш да го прошириш твојот бизнис онлајн? Добиј современа онлајн продавница што ќе те издвои од твоите конкуренти."
    },
    hosting_windows_hosting: {
      title:
        "Windows хостинг - ASP.NET хостинг планови со напредни опции - МКхост",
      meta_keywords: "windows hosting, windows хостинг, asp.net, plesk",
      meta_description:
        "Ти треба хостинг план со напредни опции и можности? Windows хостинг базиран на Plesk контролен панел е совршен избор за твојот сајт."
    },
    hosting_wordpress_hosting: {
      title: "WordPress хостинг - Брз и оптимизиран сајт - МКхост",
      meta_keywords: "wordpress hosting, wordpress хостинг, вордпрес сајт",
      meta_description:
        "Веб хостинг со инсталиран WordPress - најпопуларната платформа за управување на веб сајтови. Идеален за сајтови со многу содржина."
    },
    hosting_directadmin_hosting: {
      title: "DirectAdmin хостинг - Ефикасен и лесен за менаџирање - МКхост",
      meta_keywords: "directadmin hosting, directadmin хостинг",
      meta_description:
        "DirectAdmin е ценовно идеален хостинг и лесен за менаџирање. Брз и оптимизиран веб сајт. Професионални емаил адреси. Безбедност на информации."
    },
    servers_dedicated: {
      title: "Дедицирани сервери со брзи перформанси ",
      meta_keywords: "дедициран сервер, dedicated server",
      meta_description:
        "Ти требаат големи перформанси за твојот сајт? Закупи дедициран сервер со оптимален простор и максимален сообраќај. Идеален за големи онлајн бизниси."
    },
    cloud_servers: {
      title: "Cloud - Сигурен и безбеден сервер за твојот сајт - МКхост",
      meta_keywords: "cloud сервер, cloud server, cloud хостинг",
      meta_description:
        "Имаш брзорастечки сајт? Cloud хостинг е идеален за комплетна флексибилност во работата. Можност за неограничен раст и надоградба на ресурси на серверот."
    },
    webdesign_premium: {
      title: "Направи целосен веб сајт во 3 чекори - МКхост Премиум",
      meta_keywords: "мкхост премиум, mkhost premium, веб темплејти",
      meta_description:
        "Идеално решение за твојот онлајн бизнис. Целосен пакет кој вклучува домен, веб хостинг и темплејт за веб сајт. Стартувај со својот веб сајт денес."
    },
    webdesign_personalized: {
      title:
        "Веб дизајн, изработка и одржување на веб сајтови и веб страни - МКхост",
      meta_keywords:
        "веб дизајн, изработка на веб сајт, персонализирани веб решенија, софтверски решенија",
      meta_description:
        "Професионален веб сајт за твојот бизнис, продукт или услуга. Изработка на презентациски сајтови и онлајн продавници. Побарај понуда веднаш."
    },
    addons_ssl: {
      title: "SSL сертификати за сигурност на твојот веб сајт - МКхост",
      meta_keywords: "SSL сертификати, онлајн плаќање",
      meta_description:
        "Нашите SSL сертификати го обезбедуваат твојот сајт со највисоко ниво на сигурност. Поголема безбедност на твоите онлајн плаќања и кориснички информации."
    },
    addons_support: {
      title: "Премиум поддршка за твојот хостинг пакет - МКхост",
      meta_keywords:
        "мкхост поддршка, професионална поддршка, поддршка за хостинг",
      meta_description:
        "Активирај ја нашата услуга за Премиум поддршка и добиј 24/7 приоритет во решавање на проблемите, како и бројни други бенефити од МКхост."
    },
    addons_aws: {
      title: "AWS Cloud Services with Expert Support",
      meta_keywords: "web design, website maintenance, custom web solutions",
      meta_description: "We can help your business expand and thrive through the benefits of the cloud. Our expert AWS managed service can provide limitless possibilities for growth."
    },
    other_about_us: {
      title: "Запознај го тимот на МКхост",
      meta_keywords: "мкхост, mkhost, мкхост тим, за мкхост",
      meta_description:
        "МКхост е најголемиот македонски хостинг провајдер и регистрар на домени. Дознај повеќе за МКхост, како и тимот кој стои позади нашите продукти и услуги."
    },
    other_our_brand: {
      title: "МКхост Бренд",
      meta_keywords: "мкхост бренд, мкхост лого, мкхост бои, мкхост фонтови",
      meta_description:
        "Дознај повеќе за брендот на МКхост, лого, бои и фонтови. Симни го нашето официјално лого во различни големини и бои."
    },
    other_contact: {
      title: "МКхост Контакт",
      meta_keywords: "мкхост контакт, мкхост телефон, мкхост емаил",
      meta_description:
        "Информирај се за нашите услуги и побарај понуда и совет за твоите потреби секој работен ден од 08:00 до 16:00 "
    },
    other_legal: {
      title: "МКхост Правни напомени",
      meta_keywords:
        "мкхост услови за користење, мкхост политика за приватност",
      meta_description:
        "Дознај повеќе за нашата политика за приватност и условите за користење. Дополнителни информации за правила за регистрација на домени."
    },
    other_careers: {
      title: "Изгради ја твојата кариера во МКхост",
      meta_keywords: "мкхост кариера, отворени работни позиции",
      meta_description:
        "Нашиот тим е најважниот сегмент во работењето. Постојано сме во потрага по нови членови на тимот кои ќе помогнат во подобрување на нашите продукти и услуги."
    },
    other_blog: {
      title: "МКхост Блог",
      meta_keywords: "мкхост блог, мкхост статии, мкхост блог постови",
      meta_description: "Последни вести и статии на нашиот блог."
    },
    other_knowledgebase: {
      title: "МКхост База на знаења",
      meta_keywords: "база на знаења, помош за хостинг, помош за домен",
      meta_description:
        "Ти треба помош? Пребарај низ базата на знаења на МКхост за различни прашања поврзани со хостинг, домени, емаил и сѐ што е поврзано со твојот веб сајт."
    },
    other_first_website: {
      title: "Дом за твојот веб сајт - МКхост",
      meta_keywords: "дом за твојот веб сајт, елементи на веб сајт",
      meta_description:
        "Веројатно често се прашуваш како еден веб сајт може да функционира на интернет просторот. Научи повеќе за елементите на секој веб сајт."
    },
    other_control_panel: {
      title: "МКхост Кориснички портал",
      meta_keywords: "мкхост кориснички портал, мкхост фактури",
      meta_description:
        "Нашиот кориснички портал овозможува брз пристап до сите услуги на МКхост. Лесно управување со фактурите и контактите, како и контакт со нашата техничка поддршка."
    },
    other_young_enterpreneurs: {
      title: "Добиј персонализирано веб решение! - МКхост",
      meta_keywords: "мкхост, претприемачи, натпревар, бесплатен веб сајт",
      meta_description:
        "Внеси го твојот бизнис во дигиталниот свет со пополнување на едноставната форма и стани потенцијален добитник на бесплатен хостинг, домен и веб сајт."
    },
    other_email: {
      title: "Подеси го твојот емаил - МКхост",
      meta_keywords: "мкхост емаил, андроид емаил, ios емаил",
      meta_description:
        "Посети го нашиот водич за тоа како да го подесиш твојот емаил преку МКхост на секој уред. Достапен туторијал за Android, iOS, macOS, Outlook и Thunderbird."
    },
    other_free_ebook: {
      title: "Започни го твојот прв веб сајт - Бесплатна е-книга - МКхост",
      meta_keywords: "мкхост, прв веб сајт, бесплатна е-книга",
      meta_description:
        "Како да го започнеш твојот прв веб сајт? Симни ја нашата бесплатна е-книга со која ќе научиш како успешно да го лансираш својот онлајн бизнис."
    },
    other_hosting_cloud_consultation: {
      title: "Дознај зошто Cloud е идеален за твојот онлајн бизнис",
      meta_keywords: "мкхост, cloud, хостинг, онлајн бизнис",
      meta_description:
        "Имаш растечки сајт кој има потреба од напредни хостинг перформанси? Закажи бесплатни консултации со тимот на МКхост за да се информираш повеќе околу можностите кои ги нуди Cloud."
    },
    other_webinar_first_website: {
      title: "МКхост Вебинар: Лансирање на твојот онлајн бизнис",
      meta_keywords: "мкхост, вебинар, website, веб сајт, онлајн бизнис",
      meta_description:
        "Преку интерактивна онлајн сесија, од нашиот веб девелопмент тим ќе дознаеш детално како да ја започнеш твојата онлајн приказна."
    },
    careers: {
      sales_agent: {
        title: "Оглас за агент за продажба во МКхост",
        meta_keywords : "мкхост, оглас, агент, продажба",
        socialImage: "mkhost-team.png",
        meta_description: "Аплицирај за работна позиција во МКхост.",
        description: "Аплицирај за работна позиција во МКхост"
      },
      web_developer: {
        title: "Оглас за веб девелопер во МКхост",
        meta_keywords : "мкхост, оглас, веб, веб девелопер",
        socialImage: "mkhost-team.png",
        meta_description: "Аплицирај за работна позиција во МКхост.",
        description: "Аплицирај за работна позиција во МКхост"
      },
      system_admin:{
        title: 'Оглас за систем администратор во МКхост',
        meta_keywords : "мкхост, оглас, систем администратор",
        socialImage: "mkhost-team.png",
        meta_description: "Аплицирај за работна позиција во МКхост.",
        description: "Аплицирај за работна позиција во МКхост"
      },

      technical_support:{
        title: "Оглас за специјалист за техничка поддршка во МКхост",
        meta_keywords : "мкхост, оглас, специјалист, техничка поддршка",
        socialImage: "mkhost-team.png",
        meta_description: "Аплицирај за работна позиција во МКхост.",
        description: "Аплицирај за работна позиција во МКхост"
      },


    },
    referral_admin:{
      title:"МКхост Програма за лојалност",
      description:"Познаваш некој на кој му е потребно онлајн присуство? Препорачај го МКхост на твојот пријател, и добијте бенефит и двајцата!",
      meta_description: "Познаваш некој на кој му е потребно онлајн присуство? Препорачај го МКхост на твојот пријател, и добијте бенефит и двајцата!",
      meta_keywords : "Лојалност,бенефи,програма"

    }
  },
  en: {
    homepage: {
      title: "Web hosting and .mk domain registration - MKhost",
      meta_keywords: "mkhost, web hosting mk, mk domain",
      meta_description:
        "MKhost is the biggest Macedonian hosting provider and .mk domain registrar. Host your site at MKhost and get professional support 24/7."
    },
    domains_register: {
      title:
        "Register .mk domain - Biggest Macedonian domain registrar - MKhost",
      meta_keywords: "mk domain, macedonian domain registration",
      meta_description:
        "Need a .mk domain? MKhost is the biggest .mk domain registrar. Instant activation in 5 minutes for Macedonian and international domains. We provide 24/7 support."
    },
    domains_tlds: {
      title: "nTLD - Register New Top Level Domain - MKhost",
      meta_keywords: "ntld registration, new top level domain",
      meta_description:
        "Do you have specific business which requires specific type of domain? Register a new top level domain (nTLD) like .online or .solutions in just few steps."
    },
    other_web_solution: {
      title: "Да ти помогнеме во твојот онлајн бизнис!",
      meta_keywords:
          "веб хостинг, web hosting, хостинг провајдер,веб бизнис, онлајн бизнис,веб дизајн,web design",
      meta_description:
          "МКхост сака да ги поддржи младите креативци кои што продаваат свои изработки на социјалните мрежи, со тоа што би добиле професионална продавница каде што ќе може да ги пласираат своите продукти. Еден најсреќен добитник ќе добие целосно веб решение."
    },
    domains_transfer: {
      title: "Free transfer for your .mk domain - MKhost",
      meta_keywords: "mk domain transfer, mk domain migration",
      meta_description:
        "Join the biggest Macedonian domain registrar and move your .mk domains to MKhost free, in just several steps. 24/7 support provided."
    },
    domains_prices: {
      title: "Prices of Macedonian (.mk) and International Domains - MKhost",
      meta_keywords: "mk domain prices, mk domain registration",
      meta_description:
        "Check the prices on registering, renewing, and transferring Macedonian (.mk) and International domains. Join the biggest Macedonian domain registrar."
    },
    domains_bulk: {
      title: "Bulk options for .mk domains - Get discount - MKhost",
      meta_keywords: "mk domains bulk, mk domains discount",
      meta_description:
        "Interested in purchasing .mk domains in bulk? We offer various discounts based on your needs, as well as additional support for managing your domains."
    },
    domains_whois: {
      title: "WHOIS - Check .mk domain availability - MKhost",
      meta_keywords: "whois, domain check",
      meta_description:
        "Check if a specific .mk domain is available for purchase. The WHOIS service allows you to check the owner information of all .mk domains."
    },
    hosting_web_hosting: {
      title: "Web hosting - Biggest Macedonian web hosting provider - MKhost",
      meta_keywords: "web hosting, macedonian web hosting, linux hosting",
      meta_description:
        "Do you need a simple website? Or maybe doing a big web project? MKhost offers fast and reliable Linux web hosting plans for all your online needs."
    },
    hosting_business_hosting: {
      title: "Business hosting - Premium options for your website - MKhost",
      meta_keywords:
        "business hosting, professional email address, premium support",
      meta_description:
        "Fast and reliable hosting space for your business. Choose the ideal hosting plan and get professional emails, premium support and extra disk space."
    },
    online_shop_promotion:{
      title: "Launch your own online store",
      meta_keywords:
          "online,online store,web shop,online payment",
      meta_description:
          "Want to expand your business online? Get a modern online store that will set you apart from your competitors."
    },
    hosting_windows_hosting: {
      title: "Windows hosting - ASP.NET hosting plans - MKhost",
      meta_keywords: "windows hosting, asp.net, plesk",
      meta_description:
        "Do you need a hosting plan with advanced features? Windows hosting based offers the power and flexibility of Parallels Plesk Panel."
    },
    hosting_wordpress_hosting: {
      title: "WordPress Hosting - Focus on your website content",
      meta_keywords: "wordpress hosting, wordpress website",
      meta_description:
        "Web hosting with installed WordPress - the biggest platform for managing websites. Ideal for a site with a lot of content."
    },
    hosting_directadmin_hosting: {
      title:
        "DirectAdmin hosting - Efficient and easy manageable hosting - MKhost",
      meta_keywords: "directadmin hosting, directadmin panel",
      meta_description:
        "Do you want a web hosting that is easy to manage and cost efficient? DirectAdmin is the answer to your request. Professional email addresses and safe data."
    },
    servers_dedicated: {
      title: "Dedicated servers with fast performances - MKhost",
      meta_keywords: "dedicated server, fast server",
      meta_description:
        "Do you want optimal security and maximum bandwidth? Choose an ideal server dedicated for growing online businesses. 24/7 technical support available."
    },
    cloud_servers: {
      title: "Cloud - Fast and secure server for your website - MKhost",
      meta_keywords: "cloud server, fast server, secure server",
      meta_description:
        "Do you have a fast-growing website? Cloud is ideal for complete flexibility and unlimited expansion and growth. 24/7 technical support available."
    },
    webdesign_premium: {
      title: "Make a complete website in 3 simple steps - MKhost Premium",
      meta_keywords: "mkhost premium, web templates",
      meta_description:
        "Ideal solution for your online business. Full package that includes web hosting, domains and web templates. Order now and start with your website today."
    },
    webdesign_personalized: {
      title:
        "Web design, website maintenance and custom web solutions - MKhost",
      meta_keywords: "web design, website maintenance, custom web solutions",
      meta_description:
        "Professional website for your business by the Macedonian biggest online company. Presentation websites and online shops for your business, product or service."
    },
    addons_ssl: {
      title: "SSL certificates for your website security - MKhost",
      meta_keywords: "SSL certificates, online payment",
      meta_description:
        "Our SSL Certificates provide your website with the highest levels of security. Secure your e-commerce transactions, credit cards, and customer information."
    },
    addons_support: {
      title: "Premium support for your web hosting plan - MKhost",
      meta_keywords: "mkhost support, web hosting support",
      meta_description:
        "Activate our Premium Support addon for priority in problem solving and a number of additional support benefits. 24/7 available technical support."
    },

      addons_aws: {
          title: "AWS Cloud Services with Expert Support",
          meta_keywords: "web design, website maintenance, custom web solutions",
          meta_description: "We can help your business expand and thrive through the benefits of the cloud. Our expert AWS managed service can provide limitless possibilities for growth."
      },
    other_about_us: {
      title: "Meet the MKhost team",
      meta_keywords: "mkhost team, about mkhost",
      meta_description:
        "MKhost is the biggest Macedonian hosting provider and domain registrar. Find our more about our company and the team behind our great products and services."
    },
    other_our_brand: {
      title: "MKhost Brand",
      meta_keywords: "mkhost brand, mkhost logo",
      meta_description:
        "Find out more about the brand of MKhost, our branding, our logo, colors, fonts. Download our logo in different sizes and colors."
    },
    other_contact: {
      title: "MKhost Contact",
      meta_keywords: "mkhost contact, mkhost phone number, mkhost email",
      meta_description:
        "Inform about our services and request a quote and advice for your needs every working day from 08:00 to 16:00"
    },
    other_legal: {
      title: "MKhost Legal",
      meta_keywords: "mkhost privacy policy, mkhost terms of service",
      meta_description:
        "We value your privacy. Find out more about our Privacy Policy and Terms of Service. Additional information about regulations for registration of domains."
    },
    other_careers: {
      title: "Build your career at MKhost",
      meta_keywords: "mkhost career, open work positions",
      meta_description:
        "Our team is the most important factor in our company. We continuously look for new potential members who can help us improve our products and services."
    },
    other_blog: {
      title: "MKhost Blog",
      meta_keywords: "mkhost blog, mkhost articles, mkhost blog posts",
      meta_description: "Read the latest news about our products and services."
    },
    other_knowledgebase: {
      title: "MKhost Knowledge base",
      meta_keywords: "mkhost knowledge base, web hosting help, domain help",
      meta_description:
        "Need help? Search or browse our large collection of articles on a variety of topics including web hosting, domains, emails and everything about your website."
    },
    other_first_website: {
      title: "A home for your website - MKhost",
      meta_keywords: "a home for your website, website elements",
      meta_description:
        "Building a website has never been easier. Learn about the 3 key components of every website, and get started on your first website."
    },
    other_control_panel: {
      title: "MKhost Control Panel",
      meta_keywords: "mkhost control panel, mkhost invoices",
      meta_description:
        "Our brand new control panel gives you easy access to all of your MKhost products and services. Easily manage your billing documents, contacts, and tickets."
    },
    other_young_enterpreneurs: {
      title: "Win a personalized web solution! - MKhost",
      meta_keywords:
        "mkhost, entrepreneurship, contest, personalized web solution,",
      meta_description:
        "Enter your business into the digital world by signing up to become the potential winner of Free Hosting, Domain, and Website"
    },
    other_email: {
      title: "Setup your email - MKhost",
      meta_keywords: "mkhost email, android email, ios email",
      meta_description:
        "Visit our guide about setting your email through MKhost on every device. Available tutorial for Android, iOS, macOS, Outlook and Thunderbird."
    },
    other_free_ebook: {
      title: "Започни го твојот прв веб сајт - Бесплатна е-книга - МКхост",
      meta_keywords: "мкхост, прв веб сајт, бесплатна е-книга",
      meta_description:
        "Како да го започнеш твојот прв веб сајт? Симни ја нашата бесплатна е-книга со која ќе научиш како успешно да го лансираш својот онлајн бизнис."
    },
    other_hosting_cloud_consultation: {
      title: "Дознај зошто Cloud е идеален за твојот онлајн бизнис",
      meta_keywords: "мкхост, cloud, хостинг, онлајн бизнис",
      meta_description:
        "Имаш растечки сајт кој има потреба од напредни хостинг перформанси? Закажи бесплатни консултации со тимот на МКхост за да се информираш повеќе околу можностите кои ги нуди Cloud."
    },
    other_webinar_first_website: {
      title: "МКхост Вебинар: Лансирање на твојот онлајн бизнис",
      meta_keywords: "мкхост, вебинар, website, веб сајт, онлајн бизнис",
      meta_description:
        "Преку интерактивна онлајн сесија, од нашиот веб девелопмент тим ќе дознаеш детално како да ја започнеш твојата онлајн приказна."
    },
    careers: {
      sales_agent: {
        title: "Оглас за агент за продажба во МКхост",
        meta_keywords : "мкхост, оглас, агент, продажба",
        socialImage: "mkhost-team.png",
        meta_description: "Аплицирај за работна позиција во МКхост.",
        description: "Аплицирај за работна позиција во МКхост"
      },
      web_developer: {
        title: "Оглас за веб девелопер во МКхост",
        meta_keywords : "мкхост, оглас, веб, веб девелопер",
        socialImage: "mkhost-team.png",
        meta_description: "Аплицирај за работна позиција во МКхост.",
        description: "Аплицирај за работна позиција во МКхост"
      },
      system_admin:{
        title: 'Оглас за систем администратор во МКхост',
        meta_keywords : "мкхост, оглас, систем администратор",
        socialImage: "mkhost-team.png",
        meta_description: "Аплицирај за работна позиција во МКхост.",
        description: "Аплицирај за работна позиција во МКхост"
      },
      technical_support:{
        title: "Оглас за специјалист за техничка поддршка во МКхост",
        meta_keywords : "мкхост, оглас, специјалист, техничка поддршка",
        socialImage: "mkhost-team.png",
        meta_description: "Аплицирај за работна позиција во МКхост.",
        description: "Аплицирај за работна позиција во МКхост"
      },

    },
    referral_admin:{
      title:"MKhost Referral Program",
      description:"Do you know someone who needs an online presence? Refer MKhost’s services to your friend, and both of you will receive benefits!",
      meta_description: "Do you know someone who needs an online presence? Refer MKhost’s services to your friend, and both of you will receive benefits!",
      meta_keywords : "Referral,friend,benefits"

    }
  }
};

import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import parse from "html-react-parser";

import PrimaryButton from "../../components/Buttons/PrimaryButton";
import CustomTooltip from "../../components/CustomTooltip";

const StyledHostingPlan = styled.div`
  background-color: ${props => props.theme.white};
  margin-bottom: 30px;
  border: 1px solid
    ${props => {
      if (props.saleFlag) return props.theme.redBase;
      if (props.newFlag) return props.theme.greenBase;
      return props.theme.neutral3;
    }};
  border-radius: 3px;
  color: ${props => props.theme.neutralBase};
`;

const StyledHeader = styled.div`
  min-height: ${props => props.image && "151px"};
  background-image: url(${props => props.image});
  background-size: ${props =>
    props.imageWidth && props.imageHeight
      ? `${props.imageWidth} ${props.imageHeight}`
      : "containt"};
  background-position: center right;
  background-repeat: no-repeat;
  background-color: ${props =>
    props.dark ? props.theme.neutralBase : props.theme.blue2};
  padding: 25px 30px;
  padding-top: 36px;
  position: relative;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
`;

const StyledTitle = styled.h3`
  margin: 0;
  font-size: 28px;
  font-weight: 600;
  line-height: 1.36;
  color: ${props => (props.dark ? props.theme.white : props.theme.neutralBase)};
`;

const StyledDescription = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 1.64;
  color: ${props => (props.dark ? props.theme.white : props.theme.neutral6)};
`;

const StyledImage = styled.img`
  position: relative;
  right: -25px;
`;

const StyledContent = styled.div`
  padding: 25px 30px;
`;

const StyledWrapper = styled.div`
  margin-bottom: 30px;
`;

const StyledPrice = styled.span`
  font-size: 30px;
  font-weight: 600;
  line-height: 1.13;
  color: ${props => props.theme.blueBase};
`;

const StyledDiscountedPrice = styled.span`
  font-size: 20px;
  line-height: 1.7;
  color: ${props => props.theme.neutral5};
  margin-right: 10px;
  position: relative;
  top: -3px;
  text-decoration: line-through;
`;

const StyledDiscountedPriceWrapper = styled.div`
  max-height: 34px;
`;

const StyledPeriod = styled.span`
  font-size: 16px;
  font-weight: 400;
`;

const StyledAdditional = styled.span`
  display: block;
  font-size: 14px;
  line-height: 1.43;
  color: ${props => props.theme.neutralBase};
`;

const StyledFeature = styled.p`
  margin: 0;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1.43;
`;

const StyledReadMore = styled.span`
  display: block;
  font-size: 14px;
  line-height: 1.43;
  color: ${props => props.theme.neutral6};
  cursor: pointer;
`;

const StyledContainerBadge = styled.span`
  display: inline-block;
  position: absolute;
  top: -15px;
  left: 15px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  padding: 6px 11px;
  border-radius: 14px;
  text-transform: uppercase;
  background-color: ${props => {
    if (props.saleFlag) return props.theme.redBase;
    if (props.newFlag) return props.theme.greenBase;
  }};
  color: ${props => props.theme.white};
`;

const StyledPlanTypeImage = styled.img`
  position: absolute;
  display: block;
  width: 40px;
  height: 40px;
  right: 15px;
  top: 15px;
`;

class HostingPlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMore: false
    };
  }

  onToggleShowMore = () => {
    this.setState({ showMore: !this.state.showMore });
  };

  render() {
    const {
      title,
      description,
      image,
      imageWidth,
      imageHeight,
      price,
      discountedPrice,
      period,
      currency,
      additional,
      additionalPrices,
      features,
      newFlag,
      newContent,
      saleFlag,
      saleContent,
      action,
      dark,
      disabled,
      tooltips,
      descriptionClassName,
      hideAddToCart,
      planTypeImage,
      anchorLink
    } = this.props;

    let detailExtra = additional;
    if(additionalPrices && typeof additionalPrices.domain !== 'undefined') {
      detailExtra = additionalPrices.domain.register.discountType === "percentage" ? 
        detailExtra.replace('{ddr}', additionalPrices.domain.register.value + '%') : detailExtra.replace('{ddr}', additionalPrices.domain.register.value);

      detailExtra = additionalPrices.domain.transfer.discountType === "percentage" ? 
        detailExtra.replace('{ddt}', additionalPrices.domain.transfer.value + '%') : detailExtra.replace('{ddt}', additionalPrices.domain.transfer.value);
    }

    return (
      <Translate>
        {({ translate, activeLanguage }) => (
          <StyledHostingPlan newFlag={newFlag} saleFlag={saleFlag}>
            <StyledHeader
              image={image}
              dark={dark}
              imageWidth={imageWidth}
              imageHeight={imageHeight}
            >
              {newFlag && !saleFlag && (
                <StyledContainerBadge newFlag={newFlag}>
                  {newContent !== "" ? newContent : translate("general.new")}
                </StyledContainerBadge>
              )}

              {saleFlag && (newFlag || !newFlag) && (
                <StyledContainerBadge saleFlag={saleFlag}>
                  {saleContent !== "" ? saleContent : translate("general.sale")}
                </StyledContainerBadge>
              )}

              {planTypeImage && (
                <StyledPlanTypeImage src={planTypeImage} alt="" />
              )}
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12">
                  <StyledTitle dark={dark}>{title}</StyledTitle>
                </div>
              </div>
              <div className="row">
                <div
                  className={
                    descriptionClassName
                      ? descriptionClassName
                      : "col-12 col-sm-12 col-md-7"
                  }
                >
                  <StyledDescription dark={dark}>
                    {description}
                  </StyledDescription>
                </div>
              </div>
            </StyledHeader>
            <StyledContent>
              <StyledWrapper>
                {discountedPrice ? (
                  <StyledDiscountedPriceWrapper>
                    <StyledDiscountedPrice>
                      {currency.toUpperCase()}
                      {price}
                    </StyledDiscountedPrice>
                    <StyledPrice>
                      {currency.toUpperCase()}
                      {discountedPrice}
                      <StyledPeriod>/{period.toLowerCase()}</StyledPeriod>
                      {activeLanguage && activeLanguage.code === "mk" && (
                        <CustomTooltip
                          inline
                          content={translate("price-without-vat")}
                        >
                          <StyledPeriod>*</StyledPeriod>
                        </CustomTooltip>
                      )}
                    </StyledPrice>
                  </StyledDiscountedPriceWrapper>
                ) : (
                  <StyledPrice>
                    {currency.toUpperCase()} {price}
                    <StyledPeriod>/{period.toLowerCase()}</StyledPeriod>
                    {activeLanguage && activeLanguage.code === "mk" && (
                      <CustomTooltip
                        inline
                        content={translate("price-without-vat")}
                      >
                        <StyledPeriod>*</StyledPeriod>
                      </CustomTooltip>
                    )}
                  </StyledPrice>
                )}
                {additional && tooltips && tooltips.extra && (
                  <CustomTooltip content={tooltips.extra}>
                    <StyledAdditional>{detailExtra}*</StyledAdditional>
                  </CustomTooltip>
                )}
                {additional && !tooltips && (
                  <StyledAdditional>{detailExtra}*</StyledAdditional>
                )}
              </StyledWrapper>
              <StyledWrapper>
                {action && typeof action === "function"
                  ? action()
                  : !hideAddToCart && (
                      <PrimaryButton
                        size="l"
                        className="w-100"
                        disabled={disabled}
                      >
                        {translate("order-now")}
                      </PrimaryButton>
                    )}
              </StyledWrapper>
              <StyledWrapper>
                {features &&
                  features.length > 0 &&
                  !this.state.showMore &&
                  features
                    .slice(0, 6)
                    .map((feature, index) => (
                      <StyledFeature key={index}>
                        {parse(feature)}
                      </StyledFeature>
                    ))}

                {features &&
                  features.length > 0 &&
                  this.state.showMore &&
                  features.map((feature, index) => (
                    <StyledFeature key={index}>{parse(feature)}</StyledFeature>
                  ))}
              </StyledWrapper>
              {anchorLink ? (
                  <StyledReadMore>
                    <a href={'#' + anchorLink}>{translate("read-more")}</a>
                  </StyledReadMore>
                ) : (
                  <StyledReadMore onClick={() => this.onToggleShowMore()}>
                    {!this.state.showMore
                        ? "+ " + translate("read-more")
                        : "- " + translate("read-less")}
                  </StyledReadMore>
                )
              }
            </StyledContent>
          </StyledHostingPlan>
        )}
      </Translate>
    );
  }
}

export default HostingPlan;

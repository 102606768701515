import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";
import { Form, Field } from "react-final-form";
import { withRouter } from "react-router-dom";
import { ROUTE_OTHER_HOSTING_CLOUD_CONSULTATION_THANK_YOU } from "../../../routes";

import * as hostingCloudConsultationService from "../../../services/hostingCloudConsultationService";

import {
  validateFullName,
  validateEmail,
  validatePhoneNumberWithoutFormat
} from "../../../common/validationRules";

import Container from "../../Containers/Container";
import InputField from "../../InputField";
import TextArea from "../../TextArea";
import PrimaryButton from "../../Buttons/PrimaryButton";

const StyledProjectQuoteFormSection = styled.div``;
const StyledContainer = styled(Container)`
  padding: ${props => (props.isMobile ? "20px" : "40px")};
`;

const validate = values => {
  return {
    full_name: validateFullName(values.full_name, true),
    email: validateEmail(values.email, true),
    phone: validatePhoneNumberWithoutFormat(values.phone, true)
  };
};

class HostingCloudConsultationFormSection extends React.Component {
  onSubmit = values => {
    return hostingCloudConsultationService
      .scheduleHostingCloudConsultation(values)
      .then(() => this.props.history.push(ROUTE_OTHER_HOSTING_CLOUD_CONSULTATION_THANK_YOU));
  };

  render() {
    return (
      <Translate>
        {({ translate }) => (
          <StyledProjectQuoteFormSection className="row">
            <div className="col-12 col-sm-12 col-md-12">
              <StyledContainer isMobile={isMobileOnly}>
                <Form
                  validate={validate}
                  onSubmit={this.onSubmit}
                  render={({ handleSubmit, invalid, submitting }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12">
                          <Field
                            component={InputField}
                            label={translate("sections.hosting-cloud-consultation.first-last-name")}
                            placeholder={translate(
                              "sections.hosting-cloud-consultation.first-last-name-placeholder"
                            )}
                            name="full_name"
                          />
                          <Field
                            component={InputField}
                            label={translate("sections.hosting-cloud-consultation.email")}
                            placeholder={translate(
                              "sections.hosting-cloud-consultation.email-placeholder"
                            )}
                            name="email"
                          />
                          <Field
                            component={InputField}
                            label={translate("sections.hosting-cloud-consultation.phone")}
                            placeholder={translate(
                              "sections.hosting-cloud-consultation.phone-placeholder"
                            )}
                            name="phone"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 text-right">
                          <PrimaryButton
                            disabled={invalid || submitting}
                            submitting={submitting}
                          >
                            {translate("sections.hosting-cloud-consultation.schedule-consultation")}
                          </PrimaryButton>
                        </div>
                      </div>
                    </form>
                  )}
                />
              </StyledContainer>
            </div>
          </StyledProjectQuoteFormSection>
        )}
      </Translate>
    );
  }
}

export default withRouter(HostingCloudConsultationFormSection);

import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { Link, withRouter } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import { Helmet } from "react-helmet";

import Hero from "../../components/Hero";
import BlogPostCard from "../../components/Cards/BlogPostCard";
import { AccordionItem } from "../../components/Accordion";
import ContentWidget from "../../components/ContentWidget";
import MostPopularArticlesSection from "../../components/Sections/MostPopularArticlesSection";
import TestimonialsSection from "../../components/Sections/TestimonialsSection";
import DomainsSearchSection from "../../components/Sections/DomainsSearchSection";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import BackgroundSpacer from "../../components/BackgroundSpacer";
import HostingPlan from "../../components/HostingPlan";

import HostingPageHostingPlans from "../../components/Sections/HostingPageHostingPlans";
import PremiumPackageSection from "../../components/Sections/PremiumPackageSection";
import WhyChooseWordpressHosting from "../../components/Sections/WhyChooseWordpressHosting";
import ThirdPartyControlPanelSection from "../../components/Sections/ThirdPartyControlPanelSection";
import WhyHostWithMKhostSection from "../../components/Sections/WhyHostWithMKhostSection";
import SupportTypesSection from "../../components/Sections/SupportTypesSection";

import DataContext from "../../DataContext";
import withLoading from "../../components/Loading/WithLoading";
import ContentLoader from "../../components/Loading/ContentLoader";

import seoData from "../../seo/seoData";
import {
  ROUTE_HOSTING,
  ROUTE_HOSTING_BUSINESS_HOSTING, ROUTE_HOSTING_WEB_HOSTING,
  ROUTE_HOSTING_WINDOWS_HOSTING,
  ROUTE_HOSTING_WORDPRESS_HOSTING, ROUTE_SERVERS_CLOUD,
  ROUTE_WEBSITE_DOMAIN
} from "../../routes";

import planBubblesWordpress from "../../assets/bubbles/bubble_wordpress.svg";
import { FAQ_CATEGORY_HOSTING } from "../../config/faq";
import SubscribeFreeEbookModal from "../../components/SubscribeFreeEbookModal/SubscribeFreeEbookModal";
import * as ebookDisplayService from "../../services/ebookDisplayService";
import WordpressHostingTechnicalCharacteristics
  from "../../components/Sections/WordpressHostingTechnicalCharacteristics";
import SectionTitle from "../../components/SectionTitle";
import SectionDescription from "../../components/SectionDescription";
import SmallTitle from "../../components/SmallTitle";
import Container from "../../components/Containers/Container";
import SmallDescription from "../../components/SmallDescription";

const StyledHostingWordpressPage = styled.div`
  & .hosting-hosting-plans {
    margin-top: ${props => (props.isMobile ? "40px" : "100px")};
  }
`;
const StyledContainer = styled(Container)`
  padding: 30px;
  margin-bottom: 30px;
`;
const StyledSmallDescription = styled(SmallDescription)`
  margin-bottom: 50px;
`;
const StyledBackgroundSpacer = styled(BackgroundSpacer)`
  position: absolute;
  z-index: -1;
  top: ${props => (props.isMobile ? "0" : "-0")};
  left: 0;
  width: 100%;
`;

const HostingPlanWithLoading = withLoading(HostingPlan, ContentLoader);

const hostingPlansImages = {
  "wp basic": "/assets/icons/icon_web1.png",
  "wp regular": "/assets/icons/icon_web2.png",
  "wp power": "/assets/icons/icon_web3.png"
};

class HostingWordpressPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSubscribeFreeEbookModal: false,
      ebookSeen: ebookDisplayService.shouldEbookShow()
    };
  }

  onHideSubscribeFreeEbookModal = () => {
    this.setState({
      showSubscribeFreeEbookModal: false
    });
  };

  onScrollShowFreeEbookModal = e => {
    if (
      window.scrollY > 200 &&
      !this.state.showSubscribeFreeEbookModal &&
      !this.state.ebookSeen
    ) {
      this.setState(
        {
          showSubscribeFreeEbookModal: true,
          ebookSeen: true
        },
        () => {
          ebookDisplayService.markEbookAsSeen();
        }
      );
    }
  };

  // componentDidMount() {
  //   window.addEventListener("scroll", this.onScrollShowFreeEbookModal);
  // }

  // componentWillUnmount() {
  //   window.removeEventListener("scroll", this.onScrollShowFreeEbookModal);
  // }

  render() {
    return (
      <Translate>
        {({ translate, activeLanguage }) => (
          <StyledHostingWordpressPage isMobile={isMobileOnly}>
            {activeLanguage && activeLanguage.code && (
              <Helmet>
                <title>
                  {seoData[activeLanguage.code].hosting_wordpress_hosting.title}
                </title>
                <meta
                  name="keywords"
                  content={
                    seoData[activeLanguage.code].hosting_wordpress_hosting
                      .meta_keywords
                  }
                />
                <meta
                  name="description"
                  content={
                    seoData[activeLanguage.code].hosting_wordpress_hosting
                      .meta_description
                  }
                />
                <meta
                  property="og:title"
                  content={
                    seoData[activeLanguage.code].hosting_wordpress_hosting.title
                  }
                />
                <meta
                  property="og:description"
                  content={
                    seoData[activeLanguage.code].hosting_wordpress_hosting
                      .meta_description
                  }
                />
                <meta
                  property="og:image"
                  content={`${ROUTE_WEBSITE_DOMAIN}/assets/share/illustration_hosting.png`}
                />

                <meta
                  name="twitter:title"
                  content={
                    seoData[activeLanguage.code].hosting_wordpress_hosting.title
                  }
                />
                <meta
                  name="twitter:description"
                  content={
                    seoData[activeLanguage.code].hosting_wordpress_hosting
                      .meta_description
                  }
                />
                <meta
                  name="twitter:image"
                  content={`${ROUTE_WEBSITE_DOMAIN}/assets/share/illustration_hosting.png`}
                />
              </Helmet>
            )}
            <Hero
              title={() => (
                <span>
                  WordPress
                  <br />
                  {translate("hosting")}
                </span>
              )}
              subtitle={translate(
                "focus-on-your-content-with-fast-and-reliable-wordpress-hosting.-choose-from-3-different-plans-and-get-started-on-your-website"
              )}
              image="/assets/illustrations/hosting/illustration_hosting.png"
              backgroundImage="/assets/backgrounds/bg-1-1.svg"
              paddingBottom="0px"
              backgroundSize={isMobileOnly ? "cover" : "auto"}
            >
              <StyledBackgroundSpacer
                height={isMobileOnly ? "167px" : "253px"}
              />
            </Hero>
            <HostingPageHostingPlans>

              <DataContext.Consumer>
                {({ hostings: { wordpress }, onShowAssignDomainToHosting }) => {
                  return (
                    <div key={`hosting-plans-${Math.random()}`} className="row">
                      {!wordpress && (
                        <React.Fragment>
                          <div className="col-12 col-sm-12 col-md-4">
                            <HostingPlanWithLoading isLoading={true} />
                          </div>
                          <div className="col-12 col-sm-12 col-md-4">
                            <HostingPlanWithLoading isLoading={true} />
                          </div>
                          <div className="col-12 col-sm-12 col-md-4">
                            <HostingPlanWithLoading isLoading={true} />
                          </div>
                        </React.Fragment>
                      )}
                      {wordpress &&
                        wordpress.map(hosting => {
                          if (
                            hosting.pricing.buy.billing_cycle.derivations
                              .length === 0
                          )
                            return;

                          let possiblePeriodNumericCodes = [0.1, 0.3, 0.5, 1, 2, 3];
                          let pricing = null;

                          // const pricing = hosting.pricing.buy.billing_cycle.derivations.find(
                          //   d => d.numeric_code === 1
                          // );

                          // Temporary solution, should be sync with api derivation numeric codes
                          possiblePeriodNumericCodes.some(numericCode => {
                            pricing = hosting.pricing.buy.billing_cycle.derivations.find(
                              d => d.numeric_code === numericCode
                            );

                            if (typeof (pricing) !== 'undefined') {
                              // console.log(numericCode);
                              return true;
                            }
                          });
                          // END

                          if (!pricing) {
                            return;
                          }

                          return (
                            <div
                              key={`hosting-plan-${hosting.id}`}
                              className="col-12 col-sm-12 col-md-4"
                            >
                              <HostingPlan
                                title={hosting.name}
                                description={hosting.details.description}
                                descriptionClassName={
                                  isMobileOnly && "col-7 col-sm-7 col-md-7"
                                }
                                image={
                                  hostingPlansImages[hosting.name.toLowerCase()]
                                }
                                planTypeImage={planBubblesWordpress}
                                imageWidth="180px"
                                imageHeight="120px"
                                price={pricing.price.value}
                                period={translate(
                                  `derivations.${pricing.name.toLowerCase()}`
                                )}
                                currency={pricing.price.currency.code}
                                additional={hosting.details.extra}
                                anchorLink={"WpTechnicalCharacteristics"}
                                additionalPrices={hosting.details.prices}
                                newFlag={hosting.details.is_new}
                                newContent={hosting.details.is_new_title}
                                saleFlag={hosting.details.on_sale}
                                saleContent={hosting.details.on_sale_title}
                                features={hosting.details.features}
                                tooltips={hosting.details.tooltips}
                                action={() => (
                                  <PrimaryButton
                                    size="l"
                                    className="w-100"
                                    onClick={() =>
                                      onShowAssignDomainToHosting(hosting)
                                    }
                                  >
                                    {translate("order-now")}
                                  </PrimaryButton>
                                )}
                              />
                            </div>
                          );
                        })}
                    </div>
                  );
                }}
              </DataContext.Consumer>
            </HostingPageHostingPlans>
            <WhyChooseWordpressHosting />

            <ThirdPartyControlPanelSection
              title={() => (
                <span>
                  WordPress
                  <br /> {translate("control-panel")}
                </span>
              )}
              description={translate(
                "the-wordpress-control-panel-offers-you-smart-management-of-your-website.-choose-from-a-range-of-free-website-templates-or-thousands-of-free-plugins.-easily-manage-your-readers,-subscribers,-products,-galliers-and-more"
              )}
              image="/assets/images/image_wordpress.png"
            >
            </ThirdPartyControlPanelSection>

            <WhyHostWithMKhostSection />

            <SupportTypesSection />
            <WordpressHostingTechnicalCharacteristics />

            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-8">

                  <div style={{ "margin": "100px 0" }}>&nbsp;</div>
                  <SectionTitle>
                    {translate("need-more-functionalities")}

                  </SectionTitle>
                  <SectionDescription>
                    {translate(
                      "see-more-hosting-plans-related"
                    )}
                  </SectionDescription>
                </div>
                <div className="col-12 col-sm-12 col-md-6">
                  <StyledContainer>
                    <SmallTitle>
                      {translate("linux-hosting-pack")}
                    </SmallTitle>
                    <StyledSmallDescription>
                      {translate(
                        "linux-hosting-desc"
                      )}
                    </StyledSmallDescription>
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-6 ">
                        <Link to={ROUTE_HOSTING_WEB_HOSTING} target="_blank">
                          <PrimaryButton
                            className={isMobileOnly && "btn-block mt-2"}
                          >
                            {translate("read-more")}
                          </PrimaryButton>
                        </Link>
                      </div>
                    </div>
                  </StyledContainer>
                </div>

                <div className="col-12 col-sm-12 col-md-6">
                  <StyledContainer>
                    <SmallTitle>
                      {translate("win-hosting")}
                    </SmallTitle>
                    <StyledSmallDescription>
                      {translate("advanced-options-for-web-soliution")}
                    </StyledSmallDescription>
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-6 ">
                        <Link to={ROUTE_HOSTING_WINDOWS_HOSTING} target="_blank">
                          <PrimaryButton
                            className={isMobileOnly && "btn-block mt-2"}
                          >
                            {translate("read-more")}
                          </PrimaryButton>
                        </Link>
                      </div>
                    </div>
                  </StyledContainer>
                </div>

                <div className="col-12 col-sm-12 col-md-6">
                  <StyledContainer>
                    <SmallTitle>
                      {translate("bussiness-hosting-pack")}
                    </SmallTitle>
                    <StyledSmallDescription>
                      {translate("for-companies-who-need-big-space")}
                    </StyledSmallDescription>
                    <div className="row">

                      <div className="col-12 col-sm-12 col-md-6 ">
                        <Link to={ROUTE_HOSTING_BUSINESS_HOSTING} target="_blank">
                          <PrimaryButton
                            className={isMobileOnly && "btn-block mt-2"}
                          >
                            {translate("read-more")}
                          </PrimaryButton>
                        </Link>
                      </div>
                    </div>
                  </StyledContainer>
                </div>
                <div className="col-12 col-sm-12 col-md-6">
                  <StyledContainer>
                    <SmallTitle>
                      {translate("cloud-servers")}
                    </SmallTitle>
                    <StyledSmallDescription>
                      {translate(
                        "ideal-for-fast-grow-web-solutions"
                      )}
                    </StyledSmallDescription>
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-6 ">
                        <Link to={ROUTE_SERVERS_CLOUD} target="_blank">
                          <PrimaryButton
                            className={isMobileOnly && "btn-block mt-2"}
                          >
                            {translate("read-more")}
                          </PrimaryButton>
                        </Link>
                      </div>
                    </div>
                  </StyledContainer>
                </div>
              </div>
            </div>



            <MostPopularArticlesSection faqCategory={FAQ_CATEGORY_HOSTING} />

            {this.state.showSubscribeFreeEbookModal && (
              <SubscribeFreeEbookModal
                onCloseModal={this.onHideSubscribeFreeEbookModal}
              />
            )}
          </StyledHostingWordpressPage>

        )}
      </Translate>

    );
  }
}
export default withRouter(HostingWordpressPage);
